import {
  Box,
  Checkbox,
  Divider,
  HStack,
  Spinner,
  Stack,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  Booking,
  BookingFormElementInput,
  BookingMenu,
  LineUser,
  ProviderAccount,
  cancelEndTime,
  dateTimeStringWithWeekDay,
  isCancelable,
} from '@pochico/shared';
import dayjs from 'dayjs';
import React from 'react';

import { DisplayBooking } from '../../../firebase/types';
import { useFetchBooking } from '../../../hooks/booking';
import { useFetchBookingMenus } from '../../../hooks/bookingMenus';
import { useFetchLineUser } from '../../../hooks/lineUser';
import { LocationFrom, useLocationFrom } from '../../../hooks/locationState';
import { useIsPC } from '../../../hooks/useIsPC';
import { resourcePath } from '../../../hooks/useUrlPath';
import { BookedUserBadge } from '../../ui/BookingUser';
import { Layout } from '../../ui/Layout';
import { NavigationButton } from '../../ui/NavigationButton';
import { TextWithLinks } from '../../ui/TextWithLink';
import { TextWithNewLine } from '../../ui/TextWithNewLine';

export const BookingShow: React.FC<{
  providerAccount: ProviderAccount;
  id: string;
}> = ({ providerAccount, id }) => {
  const query = useFetchBooking({ providerAccount, id });
  const bookingMenuQuery = useFetchBookingMenus({ providerAccount });
  const lineUser = useFetchLineUser({
    providerAccount,
    id: query.data?.lineUserId,
  });
  const bookingMenu = React.useMemo(
    () =>
      (bookingMenuQuery.data || [])?.find(
        (menu) => menu.id === query.data?.bookingMenu.id
      ),
    [bookingMenuQuery.data, query.data?.bookingMenu.id]
  );
  const locationFrom = useLocationFrom({
    fallbackPath: resourcePath({
      resourceName: 'booking',
      action: 'show',
      providerAccount,
      resourceId: id,
    }),
  });

  return (
    <Layout
      hasBackButton={{
        resourceName: 'booking',
        providerAccount,
        action: 'list',
      }}
      pageTitle="予約の詳細"
    >
      <VStack
        alignItems={'flex-start'}
        width={'full'}
        padding={0}
        pt={'16px'}
        gap={0}
      >
        {query.error && <Text>エラーが発生しました {String(query.error)}</Text>}
        {!query.isLoading && query.data && bookingMenu ? (
          <BookingShowCard
            providerAccount={providerAccount}
            bookingMenu={bookingMenu}
            booking={query.data}
            lineUser={lineUser.data}
            locationFrom={locationFrom}
          />
        ) : (
          <Spinner />
        )}
      </VStack>
    </Layout>
  );
};

const BookingShowCard: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenu: BookingMenu;
  booking: DisplayBooking;
  lineUser: LineUser | undefined;
  locationFrom: LocationFrom;
}> = ({ providerAccount, bookingMenu, booking, lineUser, locationFrom }) => {
  const { cancelEnd, cancelable } = React.useMemo(() => {
    const now = dayjs();
    return {
      cancelEnd: cancelEndTime(bookingMenu, booking),
      cancelable: isCancelable(bookingMenu, booking, now),
    };
  }, [booking, bookingMenu]);
  return (
    <VStack
      w={'full'}
      borderWidth={'1px'}
      borderColor={'gray.300'}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      py={'16px'}
      borderRadius={'4px'}
      spacing={'16px'}
      divider={<StackDivider borderColor={'gray.300'} />}
    >
      <VStack w={'full'} alignItems={'flex-start'} px={'16px'}>
        <Text fontSize={'xl'} color={'gray.800'}>
          {booking.displayDate} {booking.startTime}~
        </Text>
        <Text fontSize={'2xl'} fontWeight={'bold'}>
          {bookingMenu.name}
        </Text>
        <HStack
          fontSize={'xl'}
          fontWeight={'bold'}
          color={'gray.600'}
          spacing={'8px'}
          alignItems={'baseline'}
        >
          <Text>予約ユーザー名：</Text>
          <BookedUserBadge
            booking={booking}
            providerAccount={providerAccount}
            lineUser={lineUser}
            locationFrom={locationFrom}
            to={'lineUser'}
          />
        </HStack>
        <HStack>
          <Text fontSize={'lg'}>
            キャンセル可否：{cancelable ? '可能' : '不可'}{' '}
          </Text>
          <Text fontSize={'sm'}>
            {cancelEnd && `/ 期限：${dateTimeStringWithWeekDay(cancelEnd)}`}
          </Text>
        </HStack>
        <Text fontSize={'sm'} color={'gray.500'}>
          予約作成日時：{dateTimeStringWithWeekDay(dayjs(booking.createTime))}
        </Text>
        <Text fontSize={'sm'} color={'gray.500'}>
          最終更新：
          {dateTimeStringWithWeekDay(
            dayjs(booking.updateTime || booking.createTime)
          )}
        </Text>
      </VStack>
      {booking.formResponse && (
        <BookingFormInput
          providerAccount={providerAccount}
          bookingMenu={bookingMenu}
          booking={booking}
        />
      )}
      <VStack
        w={'full'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        px={'16px'}
      >
        <Text fontSize={'sm'} fontWeight={'bold'}>
          予約メモ
        </Text>
        <Text
          fontSize={'sm'}
          bgColor={'gray.50'}
          borderWidth={'1px'}
          borderColor={'gray.200'}
          px={'16px'}
          py={'16px'}
          w={'full'}
        >
          {booking.providerMemo}
        </Text>
        <HStack w={'full'} justifyContent={'flex-end'} spacing={4}>
          <NavigationButton
            size={'sm'}
            variant={'white-blue'}
            locationFrom={locationFrom}
            to={{
              resourceName: 'booking',
              action: 'edit',
              providerAccount,
              resourceId: booking.id,
            }}
          >
            編集
          </NavigationButton>
        </HStack>
      </VStack>
    </VStack>
  );
};

const BookingFormInput: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenu: BookingMenu;
  booking: Booking;
}> = ({ providerAccount, bookingMenu, booking }) => {
  if (!booking.formResponse) {
    return <></>;
  }

  return (
    <VStack
      w={'full'}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      px={'16px'}
    >
      <Text fontSize={'lg'} fontWeight={'bold'}>
        質問フォーム回答
      </Text>

      <FormInputTable elementInputs={booking.formResponse.inputs} />
    </VStack>
  );
};

const FormInputTable: React.FC<{
  elementInputs: BookingFormElementInput[];
}> = ({ elementInputs }) => {
  // elementInputsのelement.titleをテーブルの列ヘッダーに、inputを値として表示する
  const isPC = useIsPC();
  return (
    <VStack
      w={'full'}
      alignItems={'flex-start'}
      gap={0}
      spacing={0}
      borderWidth={{ base: 0, md: '1px' }}
      borderColor={'gray.200'}
      borderRadius={'4px'}
      divider={isPC ? <> </> : <Divider borderColor={'gray.200'} />}
    >
      {elementInputs.map((elementInput, idx) => (
        <FormInputTableRow
          key={`form-row-${idx}`}
          elementInput={elementInput}
        />
      ))}
    </VStack>
  );
};

const FormInputTableRow: React.FC<{
  elementInput: BookingFormElementInput;
}> = ({ elementInput: { element, input } }) => {
  const _input = React.useMemo(() => {
    if (element.type === 'multi-select') {
      const inputs = JSON.parse(input) as string[];
      return (
        <VStack w={'full'} alignItems={'flex-start'}>
          {element.candidates.map((candidate, i) => {
            return (
              <HStack key={`element-${element.id}-${i}`}>
                <Checkbox isChecked={inputs.includes(candidate)} />
                <Text>{candidate}</Text>
              </HStack>
            );
          })}
        </VStack>
      );
    }
    if (element.type === 'agreement') {
      return (
        <VStack w={'full'} alignItems={'flex-start'} spacing={'10px'}>
          <HStack>
            <Checkbox
              isChecked={input === 'true'}
              isReadOnly
              boxSize={'24px'}
              w={'24px'}
              h={'24px'}
            />

            <Text>{element.label}</Text>
          </HStack>

          <TextWithLinks
            w={'full'}
            borderWidth={'1px'}
            borderColor={'gray.300'}
            borderRadius={'4px'}
            p={'12px'}
            maxH={'400px'}
            overflowY={'auto'}
          >
            {element.description}
          </TextWithLinks>
        </VStack>
      );
    }
    return <TextWithNewLine>{input}</TextWithNewLine>;
  }, [element, input]);

  return (
    <Stack
      w={'full'}
      alignItems={{ base: 'flex-start', md: 'stretch' }}
      direction={{ base: 'column', md: 'row' }}
      px={{ base: '0', md: '0' }}
      py={{ base: '12px', md: '0' }}
      gap={{ base: '4px', md: '0' }}
    >
      <Box
        w={{ base: 'full', md: '360px' }}
        px={{ base: 0, md: '24px' }}
        py={{ base: 0, md: '12px' }}
        bgColor={{ base: 'white', md: 'gray.100' }}
        color={'gray.500'}
      >
        {element.title}
      </Box>

      <Box px={{ base: 0, md: '24px' }} py={{ base: 0, md: '12px' }} w={'full'}>
        {_input}
      </Box>
    </Stack>
  );
};
