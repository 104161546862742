import { Box, Icon } from '@chakra-ui/react';
import React from 'react';
import { MdClose, MdOutlineCircle } from 'react-icons/md';

export const OKorNG = ({ ok }: { ok: boolean }) => {
  return (
    <Box fontSize={'xl'}>
      {ok ? (
        <Icon>
          <MdOutlineCircle />
        </Icon>
      ) : (
        <Icon>
          <MdClose />
        </Icon>
      )}
    </Box>
  );
};
