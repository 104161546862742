import { ProviderAccount } from '@pochico/shared';
import React from 'react';
import { FirebaseUser } from '../../../firebase/firebaseInit';
import { useLineChannelTypeParameter } from '../../../hooks/useLineChannelTypeParameter';
import { LineAttachDialogBodyAttachMessagingApi } from './LineAttachDialogBodyAttachMessagingApi';
import { LineAttachDialogBodyAttachModuleApi } from './LineAttachDialogBodyAttachModuleApi';

/**
 * LINE公式アカウントとの連携ダイアログ
 * 過去に連携したことがある場合にはこのダイアログのみ表示される
 * /signUpに?lineChannelTypeでLINE連携方式を指定している場合はそれに応じたダイアログを表示する
 * デフォルトはマーケットプレイス連携方式
 */
export const LineAttachDialogBodyAttach: React.FC<{
  providerAccount: ProviderAccount | undefined;
  firebaseUser: FirebaseUser;
  onClickToBack: () => void;
}> = ({ providerAccount, firebaseUser, onClickToBack }) => {
  const { lineChannelType } = useLineChannelTypeParameter();
  console.log('lineChannelType', lineChannelType);

  if (
    providerAccount?.lineChannelType === 'messagingApi' ||
    lineChannelType === 'messagingApi'
  ) {
    return (
      <LineAttachDialogBodyAttachMessagingApi
        providerAccount={providerAccount}
        firebaseUser={firebaseUser}
        onClickToBack={onClickToBack}
      />
    );
  } else {
    return (
      <LineAttachDialogBodyAttachModuleApi
        providerAccount={providerAccount}
        firebaseUser={firebaseUser}
        onClickToBack={onClickToBack}
      />
    );
  }
};
