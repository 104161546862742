import {
  Box,
  Flex,
  HStack,
  Heading,
  Icon,
  Spinner,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';
import { BookingMenu, ProviderAccount } from '@pochico/shared';
import React from 'react';

import {
  MdCallMade,
  MdCheckCircle,
  MdDoNotDisturbOn,
  MdDrafts,
} from 'react-icons/md';
import { useFetchBookingForm } from '../../../hooks/bookingForms';
import { useIsPC } from '../../../hooks/useIsPC';
import { bookingFormPreviewUrl } from '../../../models/bookingForm';
import { HintTooltip } from '../../ui/HintTooltip';
import { Layout } from '../../ui/Layout';
import { LimitDateTime } from '../../ui/LimitDateTime';
import { NavigationButton } from '../../ui/NavigationButton';
import { PreviewContainer } from '../../ui/PreviewContainer';
import { WebLink } from '../../ui/WebLink';
import { BookingMenuPreview } from './BookingMenuPreview';
import { BookingSuccessPreview } from './BookingSuccess';
import { DeleteBookingMenuButton } from './DeleteBookingMenuButton';

export const BookingMenuShow: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenu: BookingMenu;
}> = ({ providerAccount, bookingMenu }) => {
  return (
    <Layout
      hasBackButton={{
        action: 'list',
        resourceName: 'bookingMenu',
        providerAccount,
      }}
      pageTitle="予約メニュー詳細"
    >
      <BookingMenuDetail
        providerAccount={providerAccount}
        bookingMenu={bookingMenu}
      />
    </Layout>
  );
};

export const BookingMenuDetail: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenu: BookingMenu;
}> = ({ providerAccount, bookingMenu }) => {
  const isPC = useIsPC();
  const bookingFormQuery = useFetchBookingForm({
    providerAccount,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    id: bookingMenu.bookingForm?.formId || '',
    enabled: bookingMenu.bookingForm?.enabled === true,
  });
  const bookingSuccessEnabled = React.useMemo(() => {
    if (
      bookingMenu.bookingForm?.enabled &&
      !bookingMenu.bookingForm.sendBookingSuccess
    ) {
      return false;
    }
    return true;
  }, [
    bookingMenu.bookingForm?.enabled,
    bookingMenu.bookingForm?.sendBookingSuccess,
  ]);
  const bookingMenuTitle = React.useCallback(() => {
    return (
      <Box p={'16px'} w={'full'}>
        <VStack alignItems={'flex-start'} w={'full'}>
          <Heading as={'h1'} fontSize={'20px'}>
            {bookingMenu.name}
          </Heading>
          <HStack
            fontWeight={'bold'}
            color={bookingMenu.status === 'active' ? 'green.500' : 'gray.500'}
          >
            <Icon
              as={bookingMenu.status === 'active' ? MdCheckCircle : MdDrafts}
              boxSize={'24px'}
            />
            <Text>
              {bookingMenu.status === 'active' ? '公開中' : '下書き中'}
            </Text>
          </HStack>
        </VStack>
      </Box>
    );
  }, [bookingMenu.name, bookingMenu.status]);
  return (
    <Flex
      w={'full'}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      direction={isPC ? 'row' : 'column-reverse'}
      gap={{ base: '16px', md: '32px' }}
    >
      <VStack
        spacing={'20px'}
        justifyContent={'flex-start'}
        alignItems={'center'}
        flexGrow={1}
        w={'full'}
      >
        <VStack
          alignItems={'flex-start'}
          backgroundColor={'white'}
          py={'16px'}
          w={'full'}
          m={0}
          // divider={<StackDivider borderColor="gray.200" />}
          // borderWidth={'1px'}
          // borderColor={'gray.200'}
          spacing={'12px'}
        >
          {/* {providerAccount.needBookingMenu && bookingMenuTitle()} */}
          {/* {isPC && providerAccount.needBookingMenu && bookingMenuTitle()} */}
          <VStack p={'16px'} w={'full'} alignItems={'flex-start'}>
            <Text fontSize={'lg'} fontWeight={'bold'}>
              受付期間
            </Text>
            <VStack
              alignItems={'flex-start'}
              w={'full'}
              divider={<StackDivider borderColor="gray.200" />}
              borderWidth={'1px'}
              borderColor={'gray.200'}
            >
              <Box p={'16px'} w={'full'}>
                <LimitDateTime
                  bookingMenu={bookingMenu}
                  source="bookingStart"
                />
              </Box>
              <Box p={'16px'} w={'full'}>
                <LimitDateTime bookingMenu={bookingMenu} source="bookingEnd" />
              </Box>
              <Box p={'16px'} w={'full'}>
                <LimitDateTime bookingMenu={bookingMenu} source="cancelEnd" />
              </Box>
            </VStack>
          </VStack>

          <VStack p={'16px'} w={'full'} alignItems={'flex-start'}>
            <Text fontSize={'lg'} fontWeight={'bold'}>
              フォーム・その他
            </Text>
            <VStack
              alignItems={'flex-start'}
              w={'full'}
              divider={<StackDivider borderColor="gray.200" />}
              borderWidth={'1px'}
              borderColor={'gray.200'}
            >
              <VStack
                alignItems={'flex-start'}
                justifyContent={'flex-start'}
                w={'full'}
                p={'16px'}
              >
                <Text fontWeight={'bold'}>質問フォーム</Text>
                {bookingMenu.bookingForm?.enabled ? (
                  <VStack w={'full'} alignItems={'flex-start'}>
                    {bookingFormQuery.data ? (
                      <Text>{bookingFormQuery.data.name}</Text>
                    ) : (
                      // <Input
                      //   w={'full'}
                      //   value={bookingFormQuery.data.name}
                      //   readOnly
                      // />
                      <Spinner />
                    )}
                    {bookingFormQuery.data && (
                      <HStack w={'full'} justifyContent={'flex-end'}>
                        <WebLink
                          href={bookingFormPreviewUrl(
                            providerAccount,
                            bookingFormQuery.data.id
                          )}
                          target={'_blank'}
                        >
                          <HStack>
                            <Text>フォームプレビュー</Text>
                            <MdCallMade />
                          </HStack>
                        </WebLink>
                      </HStack>
                    )}
                  </VStack>
                ) : (
                  <Text>設定無し</Text>
                )}
              </VStack>
              <VStack
                p={'16px'}
                alignItems={'flex-start'}
                justifyContent={'flex-start'}
                width={'full'}
              >
                <Text fontWeight={'bold'}>予約完了時のメッセージ</Text>
                <HStack
                  fontWeight={'bold'}
                  color={bookingSuccessEnabled ? 'green.500' : 'gray.500'}
                >
                  <Icon
                    as={
                      bookingSuccessEnabled ? MdCheckCircle : MdDoNotDisturbOn
                    }
                    boxSize={'24px'}
                  />
                  <Text>
                    {bookingSuccessEnabled ? '送付する' : '送付しない'}
                  </Text>
                  <Box color={'gray.500'}>
                    <HintTooltip
                      text={
                        bookingMenu.bookingForm?.enabled &&
                        bookingSuccessEnabled
                          ? 'LINE公式アカウントのメッセージ通数を消費します'
                          : 'LINE公式アカウントのメッセージ通数を消費しません'
                      }
                    />
                  </Box>
                </HStack>
                {bookingSuccessEnabled && (
                  <Text>
                    {bookingMenu.customText?.bookingSuccess ||
                    providerAccount?.customText?.bookingSuccess
                      ? 'カスタマイズ設定あり'
                      : 'カスタマイズ設定無し'}
                  </Text>
                  // <TextWithNewLine
                  //   maxH={'10em'}
                  //   overflowY={'auto'}
                  //   borderWidth={'1px'}
                  //   borderColor={'gray.300'}
                  //   borderRadius={'4px'}
                  //   bgColor={'gray.50'}
                  //   w={'full'}
                  //   p={'12px'}
                  // >
                  //   {bookingMenu.customText?.bookingSuccess ??
                  //     (providerAccount?.customText?.bookingSuccess ||
                  //       CONSTANTS.DEFAULT_BOOKING_SUCCESS)}
                  // </TextWithNewLine>
                )}
              </VStack>
            </VStack>
          </VStack>
        </VStack>
        <HStack w={'full'} justifyContent={'flex-end'}>
          <DeleteBookingMenuButton
            providerAccount={providerAccount}
            bookingMenu={bookingMenu}
          />
          <NavigationButton
            variant={'blue-fill'}
            size={'md'}
            w={isPC ? 'fit-content' : 'full'}
            to={{
              action: 'edit',
              resourceId: bookingMenu.id,
              resourceName: 'bookingMenu',
              providerAccount,
            }}
          >
            編集する
          </NavigationButton>
        </HStack>
      </VStack>
      {(providerAccount.needBookingMenu || bookingSuccessEnabled) && (
        <VStack
          flexGrow={1}
          borderWidth={'1px'}
          borderColor={'gray.200'}
          p={'20px'}
          w={'full'}
          alignItems={'flex-start'}
          spacing={'24px'}
          backgroundColor={'white'}
        >
          <Text fontSize={'lg'} fontWeight={'bold'}>
            プレビュー
          </Text>
          {providerAccount.needBookingMenu && (
            <PreviewContainer label={'予約メニュー'}>
              <BookingMenuPreview
                bookingMenu={bookingMenu}
                showImage={Boolean(bookingMenu.imageUrl)}
              />
            </PreviewContainer>
          )}
          {bookingSuccessEnabled && (
            <BookingSuccessPreview
              providerAccount={providerAccount}
              bookingSuccessMessage={bookingMenu.customText?.bookingSuccess}
            />
          )}
        </VStack>
      )}
      {!isPC && providerAccount.needBookingMenu && bookingMenuTitle()}
    </Flex>
  );
};
