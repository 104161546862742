import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { useSetDocumentTitle } from '../commons/components/setDocumentTitle';
import { Console } from '../components/features/Console';
import LogIn from '../pages/LogIn';
import { LogOut } from '../pages/LogOut';
import { PasswordReset } from '../pages/PasswordReset';
import SignUp from '../pages/SignUp';
import { SignUpCompleted } from '../pages/SignUpCompleted';
import AttachComplete from '../pages/attach-complete';
import { AttachError } from '../pages/attach-error';
import { LineOALink } from '../pages/line-oa-link';
import Unpaid from '../pages/unpaid';
import { Path } from './Path';

// React Adminに占拠されたくないページをつくるなら(大体、基本認証外のページ)、ここでパスを切る
// Adminの中のページはこちら ./routes.tsx
const MasterRouter = () => {
  useSetDocumentTitle(''); // デフォルトのタイトルを設定しておく

  return (
    <Routes>
      <Route
        key="/____pochi"
        path="/____pochi"
        element={<div>pochi隠しページ！！</div>}
      />
      <Route key={Path.signup} path={Path.signup} element={<SignUp />} />
      <Route
        key={Path.signupComplete}
        path={Path.signupComplete}
        element={<SignUpCompleted />}
      />
      <Route key={Path.login} path={Path.login} element={<LogIn />} />
      <Route key={Path.logout} path={Path.logout} element={<LogOut />} />
      <Route
        key={Path.resetPassword}
        path={Path.resetPassword}
        element={<PasswordReset />}
      />
      <Route
        key={Path.attachComplete}
        path={Path.attachComplete}
        element={<AttachComplete />}
      />
      <Route
        key={Path.attachError}
        path={Path.attachError}
        element={<AttachError />}
      />
      {/* <Route
          key={Path.gcalAuthComplete}
          path={Path.gcalAuthComplete}
          element={<GcalAuthComplete />}
        />
        <Route
          key={Path.gcalAuthAlreadyConnected}
          path={Path.gcalAuthAlreadyConnected}
          element={<GcalAuthAlreadyCreated />}
        />
        <Route
          key={Path.paymentRegistration}
          path={Path.paymentRegistration}
          element={<PaymentRegistrationPage />}
        />
        <Route
          key={Path.paymentRegistrationComplete}
          path={Path.paymentRegistrationComplete}
          element={<PaymentRegistrationCompletePage />}
        /> */}
      <Route key={Path.unpaid} path={Path.unpaid} element={<Unpaid />} />
      {/* <Route exact path="/payment/registration/cancel" element={<ChakraPage><CheckoutCancel /></ChakraPage>} /> */}
      <Route
        key={Path.lineOaLink}
        path={Path.lineOaLink}
        element={
          <LineOALink isInitialFlow={true} lineChannelType={'moduleApi'} />
        }
      />
      <Route
        key={Path.lineOaLinkMessagingApi}
        path={Path.lineOaLinkMessagingApi}
        element={
          <LineOALink isInitialFlow={true} lineChannelType={'messagingApi'} />
        }
      />
      <Route key="/*" path="*" element={<Console />} />
    </Routes>
  );
};

export default MasterRouter;
