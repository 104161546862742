import { Spinner, useToast } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { needPayment } from '../../commons/paymentUtil';
import { Loading } from '../../components/ui/Loading';
import { useAuthState } from '../../context/providerAccount';
import { redirectBack } from '../../helpers/redirectBack';
import { ConsoleRouters } from '../../routers/ConsoleRouter';
import { Path, paymentRelatedPaths } from '../../routers/Path';
import { ConsoleLayout } from '../ui/ConsoleLayout';
import { LineAttachDialog } from './lineAttach/LineAttachDialog';

export const Console = () => {
  const location = useLocation();

  const { providerAccount } = useAuthState();

  const { result } = useCheckPreconditions();
  const toast = useToast();
  const navigate = useNavigate();
  const isPaymentRelatedPath = React.useMemo(
    () => paymentRelatedPaths.includes(location.pathname),
    [location.pathname]
  );
  const showLineAttachDialog =
    result.status === 'ng' &&
    result.error === 'not-attached' &&
    !isPaymentRelatedPath &&
    providerAccount?.status === 'detached';

  React.useEffect(() => {
    // 古いパス(/#/付き)を開いたらURLを書き換える
    if (location.hash.startsWith('#/')) {
      navigate(location.hash.replace('#/', ''), {
        replace: true,
      });
      return;
    }

    console.log('Console#useEffect', {
      result,
      location,
    });
    // 事前条件チェック
    if (result.status === 'ng') {
      toast.closeAll();
      switch (result.error) {
        case 'not-logged-in': {
          redirectBack.write(location);
          // toast({
          //   title: 'ログインしてください',
          //   status: 'warning',
          // });
          return navigate(Path.login, {
            replace: true,
          });
        }

        case 'not-attached': {
          // toast({
          //   title: `ログインしたアカウント(${providerAccount?.displayName})はポチコとLINEが連携されていません。LINE連携をおこなってください。`,
          //   status: 'error',
          // });
          // <LineAttachDialog />でLINE連携をおこなう
          break;
          // if (providerAccount?.lineChannelType === 'messagingApi') {
          //   return <Navigate replace to={Path.lineOaLinkMessagingApi} />;
          // } else {
          //   return <Navigate replace to={Path.lineOaLink} />;
          // }
        }

        case 'unpaid': {
          if (!isPaymentRelatedPath) {
            return navigate(Path.unpaid, {
              replace: true,
            });
          }
          break;
        }
      }
    }
    // if (!providerAccount) {
    //   redirectBack.write(location);
    //   toast({
    //     title: 'ログインしてください(アカウントが見つかりません)',
    //     status: 'warning',
    //   });
    //   return <Navigate replace to={Path.login} />;
    // }

    const backTo = redirectBack.readAndPurge();
    if (
      backTo &&
      backTo !== location.pathname &&
      !(
        [Path.login, Path.logout, Path.signup, Path.signupComplete] as string[]
      ).includes(backTo)
    ) {
      navigate(backTo, {
        replace: true,
      });
      return;
    }
  }, [isPaymentRelatedPath, location, navigate, result, result.status, toast]);

  // 古いパス(/#/付き)を開いたらURLを書き換える
  if (location.hash.startsWith('#/')) {
    return <Navigate replace to={location.hash.replace('#/', '')} />;
  }
  if (result.status === 'loading') {
    return <Loading />;
  } else if (result.status === 'ng') {
    if (result.error === 'not-logged-in') {
      return <Spinner />;
    }
    // return <>エラー</>;
  }

  return (
    <ConsoleLayout>
      <ConsoleRouters providerAccount={providerAccount} />
      {showLineAttachDialog && <LineAttachDialog />}
    </ConsoleLayout>
  );
};

type CheckPreconditionResult =
  | { status: 'loading' }
  | { status: 'ok' }
  | {
      status: 'ng';
      error:
        | 'not-found'
        | 'not-logged-in'
        | 'not-attached'
        | 'unpaid'
        | 'unknown';
    };

// 管理画面を使うにあたっての事前条件チェック
const useCheckPreconditions = () => {
  const location = useLocation();

  const { initialized, providerAccount, firebaseUser } = useAuthState();

  const [result, setResult] = React.useState<CheckPreconditionResult>({
    status: 'loading',
  });

  useEffect(() => {
    if (!initialized) {
      setResult({ status: 'loading' });
      return;
    }

    if (!firebaseUser) {
      setResult({ status: 'ng', error: 'not-logged-in' });
      return;
    }
    if (!providerAccount) {
      setResult({ status: 'ng', error: 'not-attached' });
      return;
    }

    // firebaseUser && providerAccountが存在する
    if (needPayment(providerAccount)) {
      setResult({ status: 'ng', error: 'unpaid' });
      return;
    } else if (providerAccount.status === 'detached') {
      setResult({ status: 'ng', error: 'not-attached' });
      return;
    }

    if (location.pathname.includes('undefined/undefined')) {
      // 初期化終わったのにproviderAccountが無いとき、パスが/undefined/undefinedとかになるのを防ぐ
      setResult({ status: 'ng', error: 'not-logged-in' });
    } else {
      // ここまで来たらOK
      setResult({ status: 'ok' });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerAccount, initialized, firebaseUser, location.pathname]);

  return {
    result,
  };
};
