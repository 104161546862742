import {
  Button,
  Flex,
  HStack,
  Select,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { BookingMenu, ProviderAccount } from '@pochico/shared';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import { atom, useAtom } from 'jotai';
import React from 'react';
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from 'react-icons/md';
import { useFetchBookings } from '../../../../hooks/booking';
import { useFetchBookingMenus } from '../../../../hooks/bookingMenus';
import { useFetchSpots } from '../../../../hooks/spots';
import { useIsPC } from '../../../../hooks/useIsPC';
import { TextEllipsis } from '../../../ui/TextEllipsis';
import {
  SpotMap,
  SpotsCalendarBody,
  useCalendarCellStyle,
} from './SpotCalendarBody';
dayjs.locale('ja');

const selectedBookingMenuIdAtom = atom<BookingMenu['id'] | undefined>(
  undefined
);

export const SpotCalendar: React.FC<{ providerAccount: ProviderAccount }> = ({
  providerAccount,
}) => {
  const { cellSize } = useCalendarCellStyle();
  const [selectedBookingMenuId, setSelectedBookingMenuId] = useAtom(
    selectedBookingMenuIdAtom
  );
  const bookingMenusQuery = useFetchBookingMenus({ providerAccount });
  const bookingMenus = React.useMemo(() => {
    return (bookingMenusQuery.data || []).filter((b) => b.status === 'active');
  }, [bookingMenusQuery.data]);

  const bookingMenu = React.useMemo(
    () =>
      selectedBookingMenuId
        ? bookingMenus.find((b) => b.id === selectedBookingMenuId)
        : undefined,
    [bookingMenus, selectedBookingMenuId]
  );

  React.useEffect(() => {
    if (bookingMenus && bookingMenus.length > 0 && !selectedBookingMenuId) {
      setSelectedBookingMenuId(bookingMenus[0].id);
    }
  }, [bookingMenus, selectedBookingMenuId, setSelectedBookingMenuId]);

  if (bookingMenusQuery.isLoading) {
    return (
      <HStack>
        <Spinner />
        <Text>Loading...</Text>
      </HStack>
    );
  }

  return (
    <VStack
      // minW={`${cellSize.width * 8}px`}
      w={'full'}
      borderColor={'gray.200'}
      borderWidth={{ base: 0, md: '1px' }}
      borderRadius={4}
      p={{ base: 0, md: '16px' }}
      spacing={4}
      justifyContent={'center'}
      alignItems={'flex-start'}
    >
      <VStack
        w={'fit-content'}
        justifyContent={'center'}
        alignItems={'flex-start'}
        spacing={'20px'}
      >
        <BookingMenuList
          providerAccount={providerAccount}
          bookingMenus={bookingMenus}
        />

        {bookingMenu ? (
          <Calendar
            providerAccount={providerAccount}
            selectedBookingMenu={bookingMenu}
            bookingMenuList={bookingMenus}
          />
        ) : bookingMenus.length > 0 ? (
          <Text>予約メニューを選択してください</Text>
        ) : (
          <Text>公開中の予約メニューがありません</Text>
        )}
      </VStack>
    </VStack>
  );
};

const BookingMenuList: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenus: BookingMenu[];
}> = ({ providerAccount, bookingMenus }) => {
  const [selectedBookingMenuId, setSelectedBookingMenuId] = useAtom(
    selectedBookingMenuIdAtom
  );
  const isPC = useIsPC();
  return (
    <Flex
      w={'full'}
      maxW={isPC ? '50vw' : 'full'}
      justifyContent={'flex-start'}
    >
      {isPC ? (
        <SimpleGrid
          columns={5}
          w={'full'}
          flexWrap={'wrap'}
          gap={'16px'}
          spacing={0}
        >
          {bookingMenus.map((menu) => {
            return (
              <Button
                key={`booking-menu-button-${menu.id}`}
                size={'md'}
                onClick={() => setSelectedBookingMenuId(menu.id)}
                fontSize={'xs'}
                variant={
                  menu.id === selectedBookingMenuId ? 'blue-fill' : 'white-blue'
                }
              >
                <TextEllipsis w={'full'} whiteSpace={'pre-wrap'} line={2}>
                  {menu.name}
                </TextEllipsis>
              </Button>
            );
          })}
        </SimpleGrid>
      ) : (
        <Select
          value={selectedBookingMenuId}
          onChange={(e) => setSelectedBookingMenuId(e.target.value)}
        >
          {bookingMenus.map((menu) => {
            return (
              <option key={`booking-menu-option-${menu.id}`} value={menu.id}>
                {menu.name}
              </option>
            );
          })}
        </Select>
      )}
    </Flex>
  );
};

const Calendar: React.FC<{
  providerAccount: ProviderAccount;
  selectedBookingMenu: BookingMenu;
  bookingMenuList: BookingMenu[];
}> = ({ providerAccount, selectedBookingMenu, bookingMenuList }) => {
  const [weekOffset, setWeekOffset] = React.useState(0);
  const today = React.useMemo(() => dayjs(), []);
  const duration = React.useMemo(() => {
    const start = today.add(weekOffset, 'week');
    const end = today.add(weekOffset + 1, 'week');
    return { start, end };
  }, [today, weekOffset]);

  const spotsQuery = useFetchSpots({
    providerAccount,
    filter: {
      bookingMenuId: selectedBookingMenu.id,
      displayDate: {
        start: duration.start.format('YYYY-MM-DD'),
        end: duration.end.format('YYYY-MM-DD'),
      },
    },
    page: 1,
    perPage: 1000,
    direction: 'asc',
  });

  const bookingsQuery = useFetchBookings({
    providerAccount,
    filter: {
      bookingMenuId: selectedBookingMenu.id,
      displayDate: {
        start: duration.start.format('YYYY-MM-DD'),
        end: duration.end.format('YYYY-MM-DD'),
      },
    },
    page: 1,
    perPage: 1000,
    sort: {
      field: 'dateTimeForSort',
      direction: 'asc',
    },
  });
  const isLoading = spotsQuery.isLoading || bookingsQuery.isLoading;
  const spotMap = React.useMemo(() => {
    const spots = spotsQuery.data || [];
    const bookings = bookingsQuery.data || [];
    return spots.reduce<SpotMap>((acc, spot) => {
      const date = spot.date;
      const hour = spot.startTime.split(':')[0];
      if (!hour) {
        return acc;
      }
      if (!acc[date]) {
        acc[date] = {};
      }
      if (!acc[date][hour]) {
        acc[date][hour] = { spots: [], bookings: [] };
      }
      acc[date][hour].spots.push(spot);
      const _bookings = bookings.filter((b) => b.spotId === spot.id);
      acc[date][hour].bookings.push(..._bookings);
      return acc;
    }, {} as SpotMap);
  }, [bookingsQuery.data, spotsQuery.data]);

  return (
    <VStack w={'full'} alignItems={'flex-start'} justifyContent={'center'}>
      <HStack w={'full'} justifyContent={'flex-start'} alignItems={'center'}>
        <HStack w={'fit-content'} alignItems={'center'} spacing={'6px'}>
          <Button
            bgColor={'transparent'}
            borderRadius={'6px'}
            size={'sm'}
            onClick={() => setWeekOffset((prev) => prev - 1)}
            px={0}
          >
            <MdOutlineKeyboardArrowLeft size={'24px'} />
          </Button>
          <Button
            bgColor={'transparent'}
            borderRadius={'6px'}
            size={'sm'}
            px={0}
            onClick={() => setWeekOffset((prev) => prev + 1)}
          >
            <MdOutlineKeyboardArrowRight size={'24px'} />
          </Button>
        </HStack>
        <Text fontWeight={'bold'} fontSize={'lg'} textAlign={'center'}>
          {duration.start.format('YYYY年M月')}
        </Text>
      </HStack>
      {isLoading ? (
        <Spinner />
      ) : (
        <SpotsCalendarBody
          providerAccount={providerAccount}
          bookingMenu={selectedBookingMenu}
          bookingMenuList={bookingMenuList}
          spotMap={spotMap}
          duration={duration}
        />
      )}
    </VStack>
  );
};
