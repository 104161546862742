import { HStack, Spinner, Text, useToast } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { setDocumentTitle } from '../../commons/components/setDocumentTitle';
import { needPayment } from '../../commons/paymentUtil';
import { LineAttachMessagingApiBody } from '../../components/features/lineAttach/LineAttachDialogBodyAttachMessagingApi';
import { RegistrationLayout } from '../../components/ui/RegistrationLayout';
import { WebLink } from '../../components/ui/WebLink';
import { useAuthState } from '../../context/providerAccount';
import { Path } from '../../routers/Path';

export const LineOALinkMessagingApi = () => {
  const navigate = useNavigate();
  const { firebaseUser, providerAccount, initialized } = useAuthState();
  const toast = useToast();

  const alreadyConfigured = React.useMemo(
    () =>
      providerAccount &&
      providerAccount.lineChannelType === 'messagingApi' &&
      providerAccount.status === 'attached',
    [providerAccount]
  );

  setDocumentTitle('ポチコLINE連携');

  React.useEffect(() => {
    if (initialized && !firebaseUser) {
      toast({
        title:
          'ログインに失敗しました。セッション切れの可能性があるので、次のページから再度ログインしてください。',
        status: 'error',
      });
      navigate(Path.login);
    } else if (initialized && providerAccount) {
      if (needPayment(providerAccount)) {
        navigate(Path.unpaid);
      } else if (alreadyConfigured) {
        // toast({
        //   title: 'すでにLINE連携が完了しています',
        //   status: 'info',
        // });
        // navigate(Path.home);
      }
    }
  }, [
    alreadyConfigured,
    firebaseUser,
    initialized,
    navigate,
    providerAccount,
    toast,
  ]);

  return (
    <RegistrationLayout>
      {!initialized || !firebaseUser ? (
        <HStack
          alignItems={'center'}
          justifyContent={'center'}
          height={'30vh'}
          fontSize={'2xl'}
        >
          <Spinner />
          <Text>Loading... ステータス: {status}</Text>
        </HStack>
      ) : (
        // LINE連携ダイアログの内容と同じものを表示するようにしておく

        <>
          <LineAttachMessagingApiBody
            firebaseUser={firebaseUser}
            providerAccount={providerAccount}
          />
          <WebLink
            fontSize={'xs'}
            alignSelf={'flex-end'}
            href={Path.logout}
            px={'8px'}
          >
            ログアウトする
          </WebLink>
        </>
        // <LineAttach initialized={initialized} firebaseUser={firebaseUser} />
      )}
    </RegistrationLayout>
  );
};
