import { ProviderAccount } from '@pochico/shared';
import React from 'react';
import { useAuthState } from '../../../context/providerAccount';
import { FirebaseUser } from '../../../firebase/firebaseInit';
import { paymentRelatedPaths } from '../../../routers/Path';
import { useDialogDispatcher } from '../../ui/Dialog';
import { LineAttachDialogBodyAttach } from './LineAttachDialogBodyAttach';
import { LineAttachDialogBodyCreate } from './LineAttachDialogBodyCreate';
import { LineAttachDialogBodyIntroduction } from './LineAttachDialogBodyIntroduction';

/**
 * LINE連携ダイアログを表示するためのコンポーネント
 * 認証されているアカウントがLINE連携されていない場合にダイアログが表示されて画面の操作をブロックする
 */
export const LineAttachDialog = () => {
  const { isOpen, openDialog } = useDialogDispatcher();
  const { firebaseUser, providerAccount, initialized } = useAuthState();

  React.useEffect(() => {
    if (!firebaseUser || providerAccount?.status === 'attached') {
      // 連携済みなら何も表示しない
      return;
    }
    if (paymentRelatedPaths.includes(location.pathname)) {
      // 支払い関係のページはそちらを優先したいので表示しない
      return;
    }
    if (isOpen) {
      // ほかでダイアログがすでに表示されている場合などには表示しない
      return;
    }

    if (initialized) {
      openDialog({
        body: (
          <DialogBody
            providerAccount={providerAccount}
            firebaseUser={firebaseUser}
          />
        ),
        closeOnOverlayClick: false,
        size: '3xl',
      });
    }
  }, [firebaseUser, initialized, isOpen, openDialog, providerAccount]);
  return null;
};

const DialogBody: React.FC<{
  providerAccount: ProviderAccount | undefined; // 過去に連携したことがなければundefined
  firebaseUser: FirebaseUser | undefined;
}> = ({ providerAccount, firebaseUser }) => {
  const [step, setStep] = React.useState<'introduction' | 'attach' | 'create'>(
    () => (firebaseUser && providerAccount ? 'attach' : 'introduction')
  );
  const onClickToAttach = React.useCallback(() => {
    setStep('attach');
  }, []);
  const onClickToCreate = React.useCallback(() => {
    setStep('create');
  }, []);
  const onClickToBack = React.useCallback(() => {
    setStep('introduction');
  }, []);
  switch (step) {
    case 'introduction': {
      return (
        <LineAttachDialogBodyIntroduction
          onClickToAttach={onClickToAttach}
          onClickToCreate={onClickToCreate}
        />
      );
    }
    case 'attach': {
      if (!firebaseUser) {
        return (
          <LineAttachDialogBodyIntroduction
            onClickToAttach={onClickToAttach}
            onClickToCreate={onClickToCreate}
          />
        );
      }
      return (
        <LineAttachDialogBodyAttach
          providerAccount={providerAccount}
          firebaseUser={firebaseUser}
          onClickToBack={onClickToBack}
        />
      );
    }
    case 'create': {
      return <LineAttachDialogBodyCreate onClickToBack={onClickToBack} />;
    }
  }
};
