import { Box, Button, HStack, Heading, Text, VStack } from '@chakra-ui/react';
import React from 'react';

import { useIsPC } from '../../../hooks/useIsPC';
import { HelpSpot } from '../../ui/HelpSpot';
import { Layout } from '../../ui/Layout';
import { Link } from '../../ui/Link';
import { WebLink } from '../../ui/WebLink';
import { LineAttachDialog } from '../lineAttach/LineAttachDialog';
import { EmptyDashboardBookingList } from './DashboardBookingList';
import { EmbedNewsNotion } from './Information';
import { EmptySpotCalendar } from './spotCalendar/EmptySpotCalendar';

/**
 * attachしていないアカウントのダッシュボード
 * LINE連携ダイアログの裏側に表示されるだけなので機能は動かなくて問題ない
 */
export const EmptyDashboardForUnAttachedAccount: React.FC = () => {
  return (
    <Layout pageTitle={'ホーム'}>
      <VStack spacing={'24px'} alignItems={'flex-start'} w={'full'}>
        <WelcomeMessage />
        <EmbedNewsNotion />

        <SpotCalendarSection />

        <BookingListSection />
      </VStack>
      <LineAttachDialog />
    </Layout>
  );
};

const SpotCalendarSection: React.FC = () => {
  const isPC = useIsPC();
  return (
    <>
      <VStack
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        w={'full'}
      >
        <HStack alignItems={'center'}>
          <Heading size={'lg'}>空き状況カレンダー</Heading>
          <HelpSpot />
        </HStack>
        {isPC ? (
          <Text color={'gray.500'}>
            ◯：予約枠に空きがある時間帯　✕：予約枠に空きがない間帯　空白：予約枠が設置されていない時間帯
          </Text>
        ) : (
          <VStack
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            color={'gray.500'}
            spacing={0}
          >
            <Text>◯：予約枠に空きがある時間帯</Text>
            <Text>✕：予約枠に空きがない間帯</Text>
            <Text>空白：予約枠が設置されていない時間帯</Text>
          </VStack>
        )}
      </VStack>
      <EmptySpotCalendar />
    </>
  );
};

const BookingListSection: React.FC = () => {
  return (
    <>
      <VStack
        pt={'56px'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        pb={'12px'}
      >
        <Heading size={'lg'}>近日中の予約</Heading>
        <Text color={'gray.500'}>
          今日と明日に入っている、受付済みの予約を確認できます。
          <Link
            to={'#'}
            color={'blue.400'}
            textDecoration={'underline'}
            fontWeight={'bold'}
            px={'4px'}
          >
            すべての予約を確認する
          </Link>
        </Text>
      </VStack>
      <EmptyDashboardBookingList />
    </>
  );
};

const WelcomeMessage: React.FC = () => {
  return (
    <VStack w={'full'} alignItems={'flex-start'} spacing={'20px'}>
      <VStack
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        borderColor={'gray.300'}
        borderWidth={'1px'}
        borderRadius={'4px'}
        w={'full'}
        bgColor={'white'}
        padding={4}
        // spacing={'16px'}
      >
        <Text fontWeight={'bold'}>ポチコ 管理者ツールへようこそ！</Text>
        <Box fontSize={'sm'}>
          管理者ツールの使い方、最初のセットアップ、より効果的な使い方など、ポチコについて困ったことがあった場合は
          <WebLink
            target="_blank"
            href="https://help.pochico.app/"
            rel="noopener"
          >
            ヘルプ
          </WebLink>
          をご確認ください。
        </Box>
        <Button w={{ base: 'full', md: 'fit-content' }} variant={'white-blue'}>
          チュートリアル
        </Button>
      </VStack>
    </VStack>
  );
};
