import { HStack, Image } from '@chakra-ui/react';
import React from 'react';

export const LineAttachImage = () => {
  return (
    <HStack w={'full'} spacing={'24px'} justifyContent={'center'}>
      <Image w={'108px'} src={'/assets/pochico-logo-square.png'} />
      <Image w={'108px'} src={'/assets/dual-arrow.png'} />
      <Image w={'108px'} src={'/assets/LINE_Brand_icon.png'} />
    </HStack>
  );
};
