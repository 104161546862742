import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightAddon,
  useToast,
  VStack,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { setDocumentTitle } from '../commons/components/setDocumentTitle';
import { Link } from '../components/ui/Link';
import { Loading } from '../components/ui/Loading';
import { PageTitle } from '../components/ui/PageTitle';
import { RegistrationLayout } from '../components/ui/RegistrationLayout';
import { WebLink } from '../components/ui/WebLink';
import { useAuthState } from '../context/providerAccount';
import { useLineChannelTypeParameter } from '../hooks/useLineChannelTypeParameter';
import authProvider from '../providers/authProvider';
import { Path } from '../routers/Path';

type LogInProps = {
  email: string;
  password: string;
};

const LogIn: FC = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const onClickPasswordVisibility = React.useCallback(
    () => setShowPassword(!showPassword),
    [showPassword]
  );
  const navigate = useNavigate();
  const { initialized, providerAccount } = useAuthState();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const {
    handleSubmit,
    getValues,
    register,
    formState: { errors },
  } = useForm<LogInProps>();
  const toast = useToast();
  const { persist } = useLineChannelTypeParameter();

  const onSubmit = React.useCallback(
    async (data: LogInProps) => {
      setSubmitting(true);
      toast.closeAll();
      try {
        const user = await authProvider.login({
          email: data.email,
          password: data.password,
        });
        console.log(`succeeded to login`, { user, providerAccount });
        persist();
        // とりあえず管理画面トップに飛ばす
        navigate(Path.home);
        // if (getAdditionalUserInfo(user)?.isNewUser) {
        //   if (lineChannelType === 'messagingApi') {
        //     navigate(Path.lineOaLinkMessagingApi);
        //   } else {
        //     navigate(Path.lineOaLink);
        //   }
        // } else {
        //   navigate(Path.home);
        // }
      } catch (e) {
        toast({
          title: `ログインに失敗しました エラー: ${e}`,
          status: 'error',
        });
        setSubmitting(false);
      }
    },
    [navigate, providerAccount, toast]
  );

  setDocumentTitle('ポチコ新規登録');

  React.useEffect(() => {
    if (initialized && providerAccount) {
      console.log('already logged-in.', { providerAccount });
      navigate(Path.home);
    }
  }, [initialized, navigate, providerAccount]);

  console.log('LogIn', { initialized, providerAccount });
  if (!initialized) {
    return <Loading />;
  }

  return (
    <RegistrationLayout>
      <VStack w={'full'}>
        <Image
          src={'/assets/footer-logo-pochico.png'}
          w={'180px'}
          objectFit={'contain'}
        />
        <PageTitle fontSize={'30px'} color={'gray.700'}>
          ログイン
        </PageTitle>
      </VStack>

      <form
        style={{ width: '100%' }}
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(getValues());
        }}
      >
        <VStack w={'full'} px={'32px'} spacing={'32px'}>
          <FormControl isInvalid={!!errors.email}>
            <FormLabel>メールアドレス</FormLabel>
            <InputGroup>
              <Input
                {...register('email', {
                  required: 'メールアドレスの入力は必須です',
                })}
                type="email"
                autoComplete={'email'}
                placeholder="example@example.co.jp"
                bgColor={'white'}
              />
            </InputGroup>
            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
          </FormControl>

          <VStack w={'full'} spacing={'12px'} alignItems={'flex-start'}>
            <FormControl isInvalid={!!errors.password}>
              <FormLabel>パスワード</FormLabel>
              <InputGroup>
                <Input
                  {...register('password', {
                    required: 'パスワードの入力は必須です',
                  })}
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="current-password"
                />
                <InputRightAddon p={0} m={0}>
                  <IconButton
                    icon={showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                    onClick={onClickPasswordVisibility}
                    aria-label="toggle password visibility"
                    // color="blue.600"
                  />
                </InputRightAddon>
              </InputGroup>
              <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
            </FormControl>

            <WebLink fontSize={'xs'} href={Path.resetPassword}>
              パスワードを忘れた
            </WebLink>
          </VStack>

          <VStack w={{ base: 'full', md: '340px' }} spacing={'12px'}>
            <Button
              type="submit"
              isLoading={submitting}
              variant={'blue-fill'}
              width={'full'}
            >
              ログイン
            </Button>

            <Button
              as={Link}
              to={Path.signup}
              colorScheme="gray"
              width={'full'}
            >
              新規登録はこちら
            </Button>
          </VStack>
        </VStack>
      </form>
    </RegistrationLayout>
  );
};

export default LogIn;
