import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from '@chakra-ui/react';
import { AttachErrorCode, sleep } from '@pochico/shared';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@tanstack/react-query';
import { MdOutlineLightbulb } from 'react-icons/md';

import {
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Box,
  Button,
  HStack,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ProviderAccount } from '@pochico/shared';
import React from 'react';
import { useAuthState } from '../../../context/providerAccount';
import { postBotApi } from '../../../dataStore/bot';
import { FirebaseUser } from '../../../firebase/firebaseInit';
import { Path } from '../../../routers/Path';
import { useDialogDispatcher } from '../../ui/Dialog';
import { ErrorCode } from '../../ui/ErrorCode';
import { FixedRight } from '../../ui/Fixed';
import { Link } from '../../ui/Link';
import { PageTitle } from '../../ui/PageTitle';
import { RightArrowWhite } from '../../ui/RightArrow';
import { WebLink } from '../../ui/WebLink';
import { LineAttachImage } from './LineAttachImage';
import { LogoutLink } from './LogoutLink';
import { TroubleShooting } from './TroubleShooting';

/**
 * LINE公式アカウントとの連携ダイアログ
 * 過去に連携したことがある場合にはこのダイアログのみ表示される
 */
export const LineAttachDialogBodyAttachMessagingApi: React.FC<{
  providerAccount: ProviderAccount | undefined;
  firebaseUser: FirebaseUser;
  onClickToBack: () => void;
}> = ({ providerAccount, firebaseUser, onClickToBack }) => {
  return (
    <AlertDialogContent mx={{ base: '16px', md: 0 }}>
      <AlertDialogHeader
        borderBottomWidth={'1px'}
        borderBottomColor={'gray.300'}
      >
        <VStack w={'full'}>
          <Text fontSize={{ base: 'lg', md: 'xl' }} color={'gray.800'}>
            {providerAccount
              ? 'LINE公式アカウントとの連携が切れています'
              : 'LINE公式アカウントをお持ちの方'}
          </Text>
        </VStack>
      </AlertDialogHeader>

      <AlertDialogBody>
        <LineAttachMessagingApiBody
          firebaseUser={firebaseUser}
          providerAccount={providerAccount}
        />
      </AlertDialogBody>

      <AlertDialogFooter justifyContent={'center'}>
        <VStack
          spacing={6}
          justifyContent={'center'}
          alignItems={'center'}
          w={'full'}
        >
          {!providerAccount && (
            <Button as={Link} onClick={onClickToBack} colorScheme={'gray'}>
              前の画面に戻る
            </Button>
          )}

          <LogoutLink />
        </VStack>
      </AlertDialogFooter>
    </AlertDialogContent>
  );
};

type LineMessagingApiParam = {
  channelId: number;
  channelSecret: string;
};
export const LineAttachMessagingApiBody: React.FC<{
  providerAccount?: ProviderAccount;
  firebaseUser: FirebaseUser;
}> = ({ providerAccount, firebaseUser }) => {
  const navigate = useNavigate();
  const [errorCode, setErrorCode] = React.useState<AttachErrorCode>();
  const { refetch } = useAuthState();
  const toast = useToast();

  const isModuleApiConnected = React.useMemo(
    () => providerAccount?.lineChannelType !== 'messagingApi',
    [providerAccount]
  );
  const alreadyConfigured = React.useMemo(
    () =>
      providerAccount &&
      providerAccount.lineChannelType === 'messagingApi' &&
      providerAccount.status === 'attached',
    [providerAccount]
  );
  const { closeDialog } = useDialogDispatcher();

  const mutation = useMutation({
    mutationFn: React.useCallback(
      async (param: LineMessagingApiParam) => {
        if (!firebaseUser) {
          return;
        }
        setErrorCode(undefined);
        return postBotApi<
          | { ok: true; providerAccount: ProviderAccount }
          | { ok: false; errorCode: AttachErrorCode }
        >('/registration', firebaseUser, {
          ...param,
        });
      },
      [firebaseUser]
    ),
    onSuccess: async (response) => {
      console.log(`response: ${JSON.stringify(response)}`);
      if (!response) {
        toast({
          title: `LINE連携に失敗しました。`,
          status: 'error',
        });
        return;
      } else {
        if (response.ok) {
          if (response.body.ok) {
            await refetch();
            if (isModuleApiConnected) {
              toast({
                title: 'LINE連携が完了しました',
                status: 'success',
              });
              await sleep(3000);
              // すでに連携済みなのでトップへ
              navigate(Path.home);
              closeDialog();
            } else {
              navigate(Path.attachComplete);
            }
          } else {
            setErrorCode(response.body.errorCode);
          }
        } else {
          setErrorCode(JSON.parse(response.error).errorCode);
          toast({
            title: `LINE連携に失敗しました。エラー: ${response.error}`,
            status: 'error',
          });
        }
      }
    },
  });

  return (
    <VStack
      alignItems={'center'}
      height={'full'}
      px={{ base: 0, md: '24px' }}
      py={'32px'}
      spacing={'32px'}
    >
      <VStack w={'full'}>
        {providerAccount && isModuleApiConnected ? (
          <PageTitle>Messaging APIでのLINE連携に切り替えます</PageTitle>
        ) : alreadyConfigured ? (
          <PageTitle>Messaging API連携情報の変更を行います</PageTitle>
        ) : (
          <></>
        )}
        <LineAttachImage />
      </VStack>
      <VStack w={'full'} spacing={'16px'} px={'16px'}>
        <Text whiteSpace={'pre-wrap'}>
          Channel IDおよびChannel Secretを入力してください。
          {(!providerAccount ||
            (!isModuleApiConnected && !alreadyConfigured)) && (
            <>
              <br />
              LINE公式アカウントがない方は事前に開設が必要です。
            </>
          )}
        </Text>

        <HStack
          borderRadius={'xl'}
          borderWidth={'1px'}
          borderColor={'gray.500'}
          bgColor={'gray.50'}
          alignItems={'center'}
          justifyContent={'center'}
          paddingX={'16px'}
          paddingY={'32px'}
        >
          <Icon as={MdOutlineLightbulb} color={'yellow.500'} boxSize={'24px'} />
          <VStack alignItems={'flex-start'}>
            <Text>
              Channel IDおよびChannel Secretの取得方法については、
              <WebLink
                href="https://help.pochico.app/47b94f9e12304cf9b88bbb95c07dbff1"
                target="_blank"
                rel="noopener noreferrer"
                color={'black'}
              >
                こちらのページ
              </WebLink>
              をご参照ください。
            </Text>
            {alreadyConfigured && (
              <Text fontWeight={'bold'}>
                セキュリティのため、設定済みのChannel IDおよびChannel
                Secretは非表示となっています。
              </Text>
            )}
          </VStack>
        </HStack>

        <InputForm
          providerAccount={providerAccount}
          onSubmit={async (param) => {
            await mutation.mutateAsync(param);
          }}
        />

        {!mutation.isPending && errorCode && (
          <Box w={['full', '452px']} color={'red'}>
            <ErrorCode errorCode={errorCode} />
          </Box>
        )}
      </VStack>
      <VStack w={'full'} spacing={'12px'}>
        <TroubleShooting />
      </VStack>
    </VStack>
  );
};

const InputForm: React.FC<{
  providerAccount: ProviderAccount | undefined;
  onSubmit: (param: LineMessagingApiParam) => Promise<void>;
}> = ({ providerAccount, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isValidating },
  } = useForm<LineMessagingApiParam>({
    mode: 'onChange',
  });
  const _onSubmit = React.useCallback(
    (param: LineMessagingApiParam) => {
      setSubmitting(true);
      onSubmit(param).finally(() => {
        setSubmitting(false);
      });
    },
    [onSubmit]
  );
  const [submitting, setSubmitting] = React.useState(false);
  return (
    <form style={{ width: '100%' }} onSubmit={handleSubmit(_onSubmit)}>
      <VStack alignItems={'flex-start'} spacing={8}>
        <Text fontWeight={'bold'}>
          アカウント名：{providerAccount?.displayName}
        </Text>
        <FormControl isInvalid={!!errors.channelId}>
          <FormLabel>Channel ID</FormLabel>
          <Input
            maxWidth={'full'}
            {...register('channelId', {
              required: true,
              maxLength: {
                value: 300,
                message: '最大文字数は300文字です',
              },
              valueAsNumber: true,
            })}
            name="channelId"
            backgroundColor={'white'}
            type="text"
            placeholder="1234567890"
          />
          <FormErrorMessage>{errors.channelId?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.channelSecret}>
          <FormLabel>Channel Secret</FormLabel>
          <Input
            maxWidth={'full'}
            {...register('channelSecret', {
              required: true,
              maxLength: {
                value: 300,
                message: '最大文字数は300文字です',
              },
            })}
            name="channelSecret"
            backgroundColor={'white'}
            type="text"
            placeholder="43d43cbd877f5d1af56ddec5fbbfbe56"
          />
          <FormErrorMessage>{errors.channelSecret?.message}</FormErrorMessage>
        </FormControl>
        <Button
          type={'submit'}
          alignSelf={'center'}
          variant={'blue-fill'}
          width={{ base: 'full', md: '400px' }}
          isDisabled={!isValidating && !isValid}
          isLoading={submitting}
        >
          {'LINE連携をする'}
          <FixedRight>
            <RightArrowWhite />
          </FixedRight>
        </Button>
      </VStack>
    </form>
  );
};
