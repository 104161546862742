import { ProviderAccount } from '@pochico/shared';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

// 登録動線やログイン時に、URLパラメータをみてLINE連携方式を取得する
const key = 'lineChannelType';
export const useLineChannelTypeParameter = () => {
  const [urlParams] = useSearchParams();
  const lineChannelType: Required<ProviderAccount['lineChannelType']> =
    React.useMemo(() => {
      const param = urlParams.get('lineChannelType');
      const type = (() => {
        if (param) {
          if (param === 'messagingApi' || param === 'moduleApi') {
            return param;
          } else {
            return 'moduleApi';
          }
        } else {
          const stored = localStorage.getItem(key);
          if (stored && (stored === 'messagingApi' || stored === 'moduleApi')) {
            return stored;
          }
          return 'moduleApi';
        }
        // persistで明示的に行う
        // localStorage.setItem(key, type);
      })();
      return type;
    }, [urlParams]);

  const persist = React.useCallback(() => {
    console.log('persist', lineChannelType);
    localStorage.setItem(key, lineChannelType);
  }, [lineChannelType]);

  const clear = React.useCallback(() => {
    localStorage.removeItem(key);
  }, []);

  return { lineChannelType, persist, clear };
};
