import React from 'react';
import { Path } from '../../../routers/Path';
import { useDialogDispatcher } from '../../ui/Dialog';
import { WebLink } from '../../ui/WebLink';

export const LogoutLink = () => {
  const { closeDialog } = useDialogDispatcher();
  return (
    <WebLink
      fontSize={'xs'}
      alignSelf={'flex-end'}
      href={Path.logout}
      onClick={closeDialog}
    >
      ログアウトする
    </WebLink>
  );
};
