import {
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Box,
  Button,
  HStack,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ProviderAccount } from '@pochico/shared';
import React from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { FirebaseUser } from '../../../firebase/firebaseInit';
import { useIsPC } from '../../../hooks/useIsPC';
import { Path } from '../../../routers/Path';
import { useDialogDispatcher } from '../../ui/Dialog';
import { Link } from '../../ui/Link';
import { LineAttachImage } from './LineAttachImage';
import { LogoutLink } from './LogoutLink';
import { TroubleShooting } from './TroubleShooting';

/**
 * LINE公式アカウントとの連携ダイアログ
 * 過去に連携したことがある場合にはこのダイアログのみ表示される
 */
export const LineAttachDialogBodyAttachModuleApi: React.FC<{
  providerAccount: ProviderAccount | undefined;
  firebaseUser: FirebaseUser;
  onClickToBack: () => void;
}> = ({ providerAccount, firebaseUser, onClickToBack }) => {
  return (
    <AlertDialogContent mx={{ base: '16px', md: 0 }}>
      <AlertDialogHeader
        borderBottomWidth={'1px'}
        borderBottomColor={'gray.300'}
      >
        <VStack w={'full'}>
          <Text fontSize={{ base: 'lg', md: 'xl' }} color={'gray.800'}>
            {providerAccount
              ? 'LINE公式アカウントとの連携が切れています'
              : 'LINE公式アカウントをお持ちの方'}
          </Text>
        </VStack>
      </AlertDialogHeader>

      <AlertDialogBody>
        <LineAttachModuleApiBody
          firebaseUser={firebaseUser}
          providerAccount={providerAccount}
        />
      </AlertDialogBody>

      <AlertDialogFooter justifyContent={'center'}>
        <VStack
          spacing={6}
          justifyContent={'center'}
          alignItems={'center'}
          w={'full'}
        >
          {!providerAccount && (
            <Button as={Link} onClick={onClickToBack} colorScheme={'gray'}>
              前の画面に戻る
            </Button>
          )}

          <LogoutLink />
        </VStack>
      </AlertDialogFooter>
    </AlertDialogContent>
  );
};
export const LineAttachModuleApiBody: React.FC<{
  providerAccount?: ProviderAccount;
  firebaseUser: FirebaseUser;
}> = ({ providerAccount, firebaseUser }) => {
  const { closeDialog } = useDialogDispatcher();
  const isPC = useIsPC();
  return (
    <VStack
      alignItems={'center'}
      height={'full'}
      px={{ base: 0, md: '24px' }}
      py={'32px'}
      spacing={'32px'}
    >
      <LineAttachImage />
      <VStack w={'full'} spacing={'16px'}>
        <Box textAlign={'center'}>
          「LINE公式アカウントとの連携に進む」からログインして
          {isPC && <br />}
          {providerAccount ? (
            <>
              「
              <Text display={'inline'} fontWeight={'bold'}>
                {providerAccount.displayName}
              </Text>
              」を選択して再連携してください。
            </>
          ) : (
            'ポチコと連携するアカウントを選択してください。'
          )}
        </Box>
        {providerAccount?.lineChannelType === 'messagingApi' ? (
          <Button
            as={Link}
            to={Path.lineOaLinkMessagingApi}
            size={'lg'}
            fontSize={'sm'}
            variant={'blue-fill'}
            onClick={closeDialog}
          >
            LINE公式アカウントとの連携に進む
          </Button>
        ) : (
          <Button
            as={Link}
            to={`${GLOBAL_CONFIG.MY_URL.SERVER.ORIGIN}/attach/${GLOBAL_CONFIG.SERVICE_NAME.UNIQ_NAME_EN}/auth?uid=${firebaseUser?.uid}`}
            size={'lg'}
            fontSize={'sm'}
            variant={'blue-fill'}
          >
            LINE公式アカウントとの連携に進む
            {!isPC && <br />}
            （別サイトに移動します）
          </Button>
        )}
        <VStack w={'full'} spacing={'12px'}>
          <HowToAttach />
          <TroubleShooting />
        </VStack>
      </VStack>
    </VStack>
  );
};

const HowToAttach: React.FC = () => {
  const [expand, setExpand] = React.useState(false);
  return (
    <VStack w={'full'} spacing={'8px'}>
      <Button
        py={'12px'}
        variant={'transparent-clickable'}
        onClick={() => setExpand(!expand)}
      >
        <HStack pl={'16px'}>
          <Text fontSize={'sm'} textDecoration={'underline'} color={'blue.600'}>
            連携方法を見る
          </Text>
          <Icon
            as={expand ? MdKeyboardArrowUp : MdKeyboardArrowDown}
            color={'gray.400'}
          />
        </HStack>
      </Button>
      {expand && (
        <video
          src={'/assets/pochico-line-attach-howto.mp4'}
          controls
          loop
          autoPlay
        />
      )}
    </VStack>
  );
};
