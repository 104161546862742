import {
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Box,
  Button,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import QRCode from 'react-qr-code';

import { ProviderAccount } from '@pochico/shared';
import CONSTANTS from '../../../../commons/constants';
import { useAuthState } from '../../../../context/providerAccount';
import { useIsPC } from '../../../../hooks/useIsPC';
import { Checkmark } from '../../../ui/Checkmark';
import { useDialogDispatcher } from '../../../ui/Dialog';
import { FixedRight } from '../../../ui/Fixed';
import { RightArrowWhite } from '../../../ui/RightArrow';
import {
  OnboardingDialogOptions,
  useOnboardingProgress,
} from './useOnboardingProgress';

export const useOnboardingBookingDialog = ({
  providerAccount,
  onCompleted,
  options,
}: {
  providerAccount: ProviderAccount;
  onCompleted: () => void;
  options?: OnboardingDialogOptions;
}) => {
  const {
    loading: progressLoading,
    onboardingProgress,
    updateProgress,
  } = useOnboardingProgress(options);
  const { isOpen, openDialog, closeDialog } = useDialogDispatcher();
  const onSubmit = React.useCallback(async () => {
    return updateProgress('createBooking').then(() => {
      closeDialog();
      return onCompleted();
    });
  }, [closeDialog, onCompleted, updateProgress]);

  const openOnboardingBookingDialog = React.useCallback(() => {
    if (isOpen || progressLoading) {
      return;
    }
    if (options?.forceOpen !== true) {
      if (onboardingProgress.createBooking) {
        // 後続はOnboardingPublishを表示するが、「次へ」ボタンを押したときだけにしたいのでskipするときにはonCompletedを呼ばない
        // TODO: 完全に実装の詳細を知っているのでいい感じに直したい(onSkipみたいなコールバックをpropsとして受け取るようにするとか)
        // onCompleted();
        return;
      }
    }
    openDialog({
      size: '3xl',
      body: <DialogBody onSubmit={onSubmit} />,
    });
  }, [
    isOpen,
    progressLoading,
    options?.forceOpen,
    openDialog,
    onSubmit,
    onboardingProgress?.createBooking,
  ]);

  return {
    loading: progressLoading,
    openOnboardingBookingDialog,
  };
};

const DialogBody: React.FC<{
  onSubmit: () => void;
}> = ({ onSubmit }) => {
  const { providerAccount } = useAuthState();
  const lineUrl = React.useMemo(() => {
    // https://developers.line.biz/ja/docs/line-login/using-line-url-scheme/#sending-text-messages
    return `https://line.me/R/oaMessage/${encodeURIComponent(
      providerAccount?.basicId || ''
    )}/?${encodeURIComponent(
      CONSTANTS.START_BOOKING_CALL_TEXT_FOR_ONBOARDING
    )}`;
  }, [providerAccount]);
  const state = useDialogDispatcher();
  const _onSubmit = React.useCallback(() => {
    state.closeDialog();
    onSubmit();
  }, [onSubmit, state]);
  const isPC = useIsPC();

  return (
    <AlertDialogContent marginX={'16px'}>
      <AlertDialogHeader>
        <VStack alignItems={'center'} justifyContent={'center'}>
          <Text fontSize="md" color={'blue.600'}>
            [チュートリアル STEP3/4]
          </Text>
          <Text fontSize="lg" fontWeight="bold">
            LINEで予約をしてみよう
          </Text>
          <Text fontSize="md">これで事前予約設定は完了です</Text>
        </VStack>
      </AlertDialogHeader>
      <AlertDialogCloseButton />

      <AlertDialogBody>
        <VStack
          alignItems={'center'}
          justifyContent={'space-around'}
          height={'full'}
        >
          <Box color={'green.400'} w={'140px'} h={'140px'}>
            <Checkmark />
          </Box>
          <VStack textAlign={'center'} w={'full'} spacing={'2em'}>
            <Text whiteSpace={'nowrap'}>
              お客様が実際に利用する画面を確認して、
              <br />
              実際に予約をしてみましょう。
            </Text>
            <Box>
              {isPC
                ? '下のQRコードをスマートフォンで読み込んでLINEを立ち上げると、'
                : '『LINEから予約してみる』のボタンを押してLINEを立ち上げると、'}
              <br />
              [予約する] というテキストがチャット欄に入っているので、
              <br />
              そのまま送信してください。
            </Box>
          </VStack>
        </VStack>
      </AlertDialogBody>

      <AlertDialogFooter justifyContent={'center'}>
        <VStack
          spacing={6}
          justifyContent={'center'}
          alignItems={'center'}
          w={'full'}
        >
          <MobileView style={{ width: '100%' }}>
            <Link target={'_blank'} rel={'noopener noreferrer'} href={lineUrl}>
              <Button width={'full'} colorScheme={'pochico.green'} size={'md'}>
                LINEから予約してみる
              </Button>
            </Link>
          </MobileView>
          <BrowserView>
            <QRCode value={lineUrl} size={128} />
          </BrowserView>
          <VStack alignItems={'flex-end'} w={'full'}>
            <Button
              w={{ base: 'full', md: '220px' }}
              variant={'blue-fill'}
              onClick={_onSubmit}
            >
              次へ
              <FixedRight>
                <RightArrowWhite />
              </FixedRight>
            </Button>
          </VStack>
        </VStack>
      </AlertDialogFooter>
    </AlertDialogContent>
  );
};
