import {
  Badge,
  Box,
  Button,
  Checkbox,
  HStack,
  Spinner,
  Stack,
  Tab,
  TabIndicator,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { BookingMenu, ProviderAccount, spotStatus } from '@pochico/shared';
import dayjs from 'dayjs';
import React from 'react';

import { useAtom, useAtomValue } from 'jotai';
import {
  BulkActionContextProvider,
  useBulkActionContext,
} from '../../../context/bulkAction';
import { DisplaySpot } from '../../../firebase/types';
import { paginationAtom } from '../../../helpers/perPage';
import { useFetchBookingMenus } from '../../../hooks/bookingMenus';
import { createLocationFrom, LocationFrom } from '../../../hooks/locationState';
import {
  useDeleteSpots,
  useFetchSpotCount,
  useFetchSpots,
} from '../../../hooks/spots';
import { useIsPC } from '../../../hooks/useIsPC';
import { useShowTooManyResultWarning } from '../../../hooks/useShowTooManyResultWarning';
import { useUrlParamState } from '../../../hooks/useUrlParamState';
import { resourcePath } from '../../../hooks/useUrlPath';
import { HelpSpot } from '../../ui/HelpSpot';
import { Layout } from '../../ui/Layout';
import { useLoadingOverlayContext } from '../../ui/LoadingOverlay';
import { NavigationButton } from '../../ui/NavigationButton';
import { OKorNG } from '../../ui/OKorNG';
import { PageTitle } from '../../ui/PageTitle';
import { PaginationIndicator } from '../../ui/PaginationIndicator';
import { SortableTableHeader } from '../../ui/SortableTableHeader';
import { SpotFilter } from '../Spots';
import { SpotBookingUsers } from './spotBookingUsers';
import { SpotFilterInputs } from './SpotFilterInputs';
import { SpotListCalendarView } from './SpotListCalendarView';
import { isSpotBookable, spotStatusText } from './spotStatus';

const spotListPagingAtom = paginationAtom<DisplaySpot>({
  page: 1,
  sort: {
    field: 'dateTimeForSort',
    direction: 'asc',
  },
});

export const SpotList: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  const bookingMenuQuery = useFetchBookingMenus({ providerAccount });
  const isPC = useIsPC();
  const { urlState, setUrlState } = useUrlParamState<{
    tab: 'calendar' | 'list';
  }>(['tab'], {
    defaultValue: {
      tab: isPC ? 'list' : 'calendar',
    },
  });

  return (
    <BulkActionContextProvider>
      <Layout
        pageTitle={
          <HStack>
            <PageTitle>予約枠を管理</PageTitle>
            <HelpSpot />
          </HStack>
        }
      >
        <VStack alignItems={'flex-start'} spacing={'16px'}>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            width={'full'}
            alignItems={{ base: 'flex-start', md: 'center' }}
            justifyContent={'space-between'}
          >
            <NavigationButton
              size={'sm'}
              variant="blue-fill"
              w={{ base: 'full', md: 'fit-content' }}
              to={{
                providerAccount: providerAccount,
                resourceName: 'spot',
                action: 'create',
              }}
            >
              枠を追加
            </NavigationButton>
          </Stack>
          {bookingMenuQuery.error ? (
            <VStack alignItems={'flex-start'}>
              <Text>エラーが発生しました</Text>
              <Text>{String(bookingMenuQuery.error)}</Text>
            </VStack>
          ) : !bookingMenuQuery.isFetching ? (
            <Tabs
              w={'full'}
              defaultIndex={urlState.tab === 'calendar' ? 0 : 1}
              borderColor={'gray.200'}
              onChange={(index) => {
                setUrlState({ tab: index === 0 ? 'calendar' : 'list' });
              }}
            >
              <TabList w={'full'} justifyContent={'center'}>
                <Tab>カレンダー表示</Tab>
                <Tab>リスト表示</Tab>
              </TabList>
              <TabIndicator
                mt="-1.5px"
                height="2px"
                bg="blue.500"
                borderRadius="1px"
              />

              <TabPanels w={'full'}>
                <TabPanel w={'full'} justifyContent={'center'}>
                  <SpotListCalendarView
                    providerAccount={providerAccount}
                    bookingMenuList={bookingMenuQuery.data || []}
                  />
                </TabPanel>
                <TabPanel w={'full'} justifyContent={'center'}>
                  <SpotListView
                    providerAccount={providerAccount}
                    bookingMenuList={bookingMenuQuery.data || []}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          ) : (
            <Spinner />
          )}
        </VStack>
      </Layout>
    </BulkActionContextProvider>
  );
};

const SpotListView: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenuList: BookingMenu[];
}> = ({ providerAccount, bookingMenuList }) => {
  const [paging, setPaging] = useAtom(spotListPagingAtom);
  const now = React.useMemo(() => dayjs(), []);
  const { urlState: filter, setUrlState: setFilter } =
    useUrlParamState<SpotFilter>(
      ['bookingMenuId', 'bulkSpotAddHistoryId', 'displayDate'],
      {
        defaultValue: {
          displayDate: {
            start: now.format('YYYY-MM-DD'),
            end: now.add(1, 'month').format('YYYY-MM-DD'),
          },
        },
      }
    );

  const countQuery = useFetchSpotCount({
    providerAccountId: providerAccount.id,
    filter,
  });
  const query = useFetchSpots({
    providerAccount,
    filter,
    perPage: paging.perPage,
    direction: paging.sort.direction,
    page: paging.page || 1,
  });

  const onClickColumnHeader = React.useCallback(
    (column: keyof DisplaySpot) => {
      if (column === 'dateTimeForSort') {
        setPaging((paging) => ({
          ...paging,
          page: 1,
          lastCursor: undefined,
          sort: {
            field: 'dateTimeForSort',
            direction: paging.sort.direction === 'asc' ? 'desc' : 'asc',
          },
        }));
      }
    },
    [setPaging]
  );
  const locationFrom = React.useMemo(
    () =>
      createLocationFrom(
        resourcePath({
          providerAccount,
          resourceName: 'spot',
          action: 'list',
          urlParams: {
            bookingMenuId: filter.bookingMenuId || '',
            bulkSpotAddHistoryId: filter.bulkSpotAddHistoryId || '',
            displayDate: JSON.stringify(filter.displayDate),
            tab: 'list',
          },
        })
      ),
    [
      filter.bookingMenuId,
      filter.bulkSpotAddHistoryId,
      filter.displayDate,
      providerAccount,
    ]
  );

  useShowTooManyResultWarning(countQuery.data);

  return (
    <VStack
      alignItems={'flex-start'}
      width={'full'}
      padding={0}
      gap={0}
      // paddingTop={'16px'}
    >
      <SpotFilterInputs
        providerAccount={providerAccount}
        bookingMenus={bookingMenuList}
        filter={filter}
        applyFilter={(newFilter) => {
          setFilter(newFilter);
          setPaging((paging) => ({
            ...paging,
            page: 1,
            lastCursor: undefined,
          }));
        }}
      />
      {countQuery.error || query.error ? (
        <VStack alignItems={'flex-start'}>
          <Text>エラーが発生しました</Text>
          <Text>{`${countQuery.error || query.error}`}</Text>
        </VStack>
      ) : !query.isFetching && !countQuery.isFetching ? (
        <SpotListTable
          providerAccount={providerAccount}
          bookingMenuList={bookingMenuList}
          spots={query.data || []}
          onClickColumnHeader={onClickColumnHeader}
          totalCount={countQuery.data || 0}
          locationFrom={locationFrom}
        />
      ) : (
        <Spinner />
      )}
    </VStack>
  );
};

const SpotListTable: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenuList: BookingMenu[];
  spots: DisplaySpot[];
  onClickColumnHeader: (column: keyof DisplaySpot) => void;
  totalCount: number;
  locationFrom: LocationFrom;
  // sortDirection: SortDirection;
}> = ({
  providerAccount,
  bookingMenuList,
  spots,
  onClickColumnHeader,
  totalCount,
  locationFrom,
  // sortDirection,
}) => {
  const [paging, setPaging] = useAtom(spotListPagingAtom);
  const {
    sort: { direction: sortDirection },
  } = useAtomValue(spotListPagingAtom);
  const { selectedIds, setSelectedIds, clearSelectedIds, bulkAction } =
    useBulkActionContext();
  const onCheckboxClicked = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;
      if (checked) {
        setSelectedIds(spots.map((spot) => spot.id));
      } else {
        clearSelectedIds();
      }
    },
    [clearSelectedIds, setSelectedIds, spots]
  );
  const deleteMutation = useDeleteSpots(providerAccount);
  const toast = useToast();

  const overlayContext = useLoadingOverlayContext();
  const onClickDelete = React.useCallback(
    () =>
      bulkAction((spotIds: string[]) => {
        return {
          title: `${spotIds.length}件の予約枠を削除しますか？`,
          body: (
            <>
              <Text>
                元に戻すことはできません。
                <br />
                また、すでに予約が取られている場合は削除できません
              </Text>
            </>
          ),
          cancelText: 'キャンセル',
          submitText: '削除する',
          onSubmit: async () => {
            overlayContext.onOpen();
            return deleteMutation
              .mutateAsync(spotIds)
              .then(() => {
                clearSelectedIds();
                toast({
                  title: `選択した予約枠を削除しました`,
                  status: 'success',
                });
              })
              .catch((e) => {
                toast({
                  title: `選択した予約枠の削除に失敗しました ${e}`,
                  status: 'error',
                });
              })
              .finally(overlayContext.onClose);
          },
        };
      }),
    [bulkAction, clearSelectedIds, deleteMutation, overlayContext, toast]
  );
  const onClickColumnToChangeSort = React.useCallback(
    (column: keyof DisplaySpot) => {
      return () => onClickColumnHeader(column);
    },
    [onClickColumnHeader]
  );
  const paginationIndicator = React.useMemo(() => {
    return (
      <PaginationIndicator
        total={totalCount}
        perPage={paging.perPage}
        page={paging.page || 1}
        isLoading={false}
        onChange={setPaging}
      />
    );
  }, [paging.page, paging.perPage, setPaging, totalCount]);
  const isPC = useIsPC();

  return (
    <VStack spacing={'8px'} w={'full'}>
      {isPC && (
        <HStack w={'full'} alignItems={'center'}>
          {selectedIds.length > 0 && (
            <Button
              w={'20em'}
              colorScheme="red"
              onClick={onClickDelete}
              isLoading={deleteMutation.isPending}
              isDisabled={selectedIds.length === 0}
            >
              選択した{selectedIds.length}件の予約枠を削除する
            </Button>
          )}
          {paginationIndicator}
        </HStack>
      )}

      <TableContainer w={'full'}>
        <Table variant="pochico-striped">
          <Thead>
            <Tr height={'3rem'} paddingY={'10px'}>
              <Th textAlign={'center'} minWidth={'fit-content'}>
                <Checkbox bg={'white'} onChange={onCheckboxClicked} />
              </Th>
              <SortableTableHeader
                onClick={onClickColumnToChangeSort('dateTimeForSort')}
                sortDirection={sortDirection}
              >
                予約日
              </SortableTableHeader>
              <Th>開始時間</Th>
              <Th>予約メニュー</Th>
              <Th>
                <HStack justifyContent={'space-between'}>
                  <Text>予約ユーザー名</Text>
                  {/* <HStack>
                    <Text>全件表示</Text>
                    <Switch checked />
                  </HStack> */}
                </HStack>
              </Th>
              <Th>予約受付期間</Th>
              <Th>空き状況</Th>
              <Th>予約可否</Th>
              <Th>操作</Th>
            </Tr>
          </Thead>
          <Tbody>
            {spots.length > 0 ? (
              spots.map((spot) => {
                return (
                  <SpotTableRow
                    spot={spot}
                    key={spot.id}
                    providerAccount={providerAccount}
                    bookingMenus={bookingMenuList}
                    locationFrom={locationFrom}
                  />
                );
              })
            ) : (
              <Tr>
                <Td colSpan={6}>データがありません</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
      {isPC ? (
        paginationIndicator
      ) : selectedIds.length > 0 ? (
        <HStack
          w={'full'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Text fontWeight={'bold'}>
            {selectedIds.length}件の予約枠を選択中
          </Text>

          <Button
            w={'fit-content'}
            colorScheme={'red'}
            onClick={onClickDelete}
            isLoading={deleteMutation.isPending}
            isDisabled={selectedIds.length === 0}
          >
            削除する
          </Button>
        </HStack>
      ) : (
        paginationIndicator
      )}
    </VStack>
  );
};

const SpotTableRow: React.FC<{
  providerAccount: ProviderAccount;
  spot: DisplaySpot;
  bookingMenus: BookingMenu[];
  locationFrom: LocationFrom;
}> = ({ providerAccount, spot, bookingMenus, locationFrom }) => {
  const bookingMenu = React.useMemo(
    () => bookingMenus.find((menu) => menu.id === spot.bookingMenuId),
    [bookingMenus, spot.bookingMenuId]
  );
  const { selectedIds, setSelectedIds } = useBulkActionContext();
  const onCheckboxClicked = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;
      if (checked) {
        setSelectedIds([...selectedIds, spot.id]);
      } else {
        setSelectedIds(selectedIds.filter((id) => id !== spot.id));
      }
    },
    [setSelectedIds, spot.id, selectedIds]
  );
  const checked = React.useMemo(() => {
    return selectedIds.includes(spot.id);
  }, [spot.id, selectedIds]);
  const status = React.useMemo(() => {
    if (!bookingMenu) {
      return undefined;
    }
    return spotStatus(bookingMenu, spot, dayjs());
  }, [bookingMenu, spot]);
  const statusText = React.useMemo(() => {
    if (!status) {
      return '受付終了';
    }
    return spotStatusText(status);
  }, [status]);
  const isBookable = React.useMemo(() => {
    if (!bookingMenu || !status) {
      return false;
    }
    return isSpotBookable(bookingMenu, spot, status);
  }, [bookingMenu, spot, status]);

  return (
    <Tr justifyContent={'center'} alignSelf={'center'}>
      <Td textAlign={'center'} minWidth={'fit-content'}>
        <Checkbox
          bg={'white'}
          onChange={onCheckboxClicked}
          isChecked={checked}
        />
      </Td>
      <Td verticalAlign={'middle'}>{spot.displayDate}</Td>
      <Td verticalAlign={'middle'}>{spot.startTime}</Td>
      <Td verticalAlign={'middle'}>
        <HStack
        // color={bookingMenu?.status === 'suspended' ? 'gray.500' : undefined}
        >
          {bookingMenu?.status === 'suspended' ? (
            <Badge
              variant={'subtle'}
              colorScheme={'gray'}
              fontSize={'xs'}
              fontWeight={'normal'}
            >
              下書き
            </Badge>
          ) : (
            <Badge
              variant={'subtle'}
              colorScheme={'green'}
              fontSize={'xs'}
              fontWeight={'normal'}
            >
              公開中
            </Badge>
          )}
          <Box>{bookingMenu?.name}</Box>
        </HStack>
      </Td>
      <Td verticalAlign={'middle'}>
        <SpotBookingUsers
          providerAccount={providerAccount}
          spot={spot}
          locationFrom={locationFrom}
        />
      </Td>
      <Td verticalAlign={'middle'}>
        <Box>{statusText}</Box>
      </Td>
      <Td verticalAlign={'middle'}>
        <HStack
          spacing={0}
          color={
            spot.bookingIds.length >= spot.maxBookings
              ? 'red.500'
              : // : statusText === '終了' || statusText === '受付終了'
                // ? 'gray.500'
                undefined
          }
        >
          <Box>{spot.displayAvailability}</Box>
          <Box>
            {spot.bookingIds.length >= spot.maxBookings ? '[満席]' : ''}
          </Box>
        </HStack>
      </Td>
      <Td>
        <OKorNG ok={isBookable} />
      </Td>
      <Td verticalAlign={'middle'}>
        <NavigationButton
          size={'sm'}
          variant="blue-fill"
          locationFrom={locationFrom}
          to={{
            providerAccount: providerAccount,
            resourceName: 'spot',
            resourceId: spot.id,
            action: 'show',
          }}
        >
          詳細
        </NavigationButton>
      </Td>
    </Tr>
  );
};
