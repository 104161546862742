import {
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Box,
  Button,
  HStack,
  Radio,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { BrowserView, MobileOnlyView } from 'react-device-detect';
import { useIsPC } from '../../../hooks/useIsPC';
import { useDialogDispatcher } from '../../ui/Dialog';
import { WebLink } from '../../ui/WebLink';
import { LogoutLink } from './LogoutLink';

/**
 * 過去に連携したことがない場合のダイアログ
 * LINE公式アカウントを持っているかどうかを尋ねる
 */
export const LineAttachDialogBodyIntroduction: React.FC<{
  onClickToAttach: () => void;
  onClickToCreate: () => void;
}> = ({ onClickToAttach, onClickToCreate }) => {
  const isPC = useIsPC();
  const { closeDialog } = useDialogDispatcher();
  return (
    <AlertDialogContent mx={{ base: '16px', md: 0 }}>
      <AlertDialogHeader
        borderBottomWidth={'1px'}
        borderBottomColor={'gray.300'}
      >
        <VStack
          w={'full'}
          // p={0}
          // px={'24px'}
          // py={'16px'}
        >
          <MobileOnlyView>
            <Text fontSize="lg" color={'gray.800'} textAlign={'center'}>
              まずは機能を使うために
              <br />
              LINEと連携してみましょう
            </Text>
          </MobileOnlyView>
          <BrowserView>
            <Text fontSize="xl" color={'gray.800'}>
              まずは機能を使うためにLINEと連携してみましょう
            </Text>
          </BrowserView>
        </VStack>
        {/* <Divider /> */}
      </AlertDialogHeader>

      <AlertDialogBody>
        <VStack
          alignItems={'center'}
          justifyContent={'space-around'}
          height={'full'}
          px={{ base: 0, md: '24px' }}
          py={'32px'}
          gap={'32px'}
        >
          <Text
            textAlign={'center'}
            fontWeight={'bold'}
            color={'gray.800'}
            fontSize={'lg'}
          >
            店舗等のLINE公式アカウントは
            {!isPC && <br />}
            お持ちですか？
          </Text>
          <VStack w={'full'} spacing={'16px'}>
            <Button
              variant={'transparent-clickable'}
              w={'full'}
              px={'20px'}
              py={'16px'}
              size={{ base: 'md', md: 'lg' }}
              fontWeight={'normal'}
              borderRadius={'4px'}
              borderWidth={'1px'}
              borderColor={'gray.300'}
              onClick={onClickToAttach}
            >
              <HStack w={'full'} justifyContent={'flex-start'}>
                <Radio />
                <Text>持っている（アカウント連携を行う）</Text>
              </HStack>
            </Button>
            <Button
              variant={'transparent-clickable'}
              w={'full'}
              px={'20px'}
              py={'16px'}
              size={{ base: 'md', md: 'lg' }}
              fontWeight={'normal'}
              borderRadius={'4px'}
              borderWidth={'1px'}
              borderColor={'gray.300'}
              onClick={onClickToCreate}
            >
              <HStack w={'full'} justifyContent={'flex-start'}>
                <Radio />
                <Text>持っていない</Text>
              </HStack>
            </Button>
          </VStack>
          <Box
            w={'full'}
            textAlign={'center'}
            color={'gray.500'}
            fontSize={'sm'}
          >
            Messaging APIを利用してポチコと連携したい /
            他のサービスと併用して連携したい方は
            <WebLink
              href={'https://help.pochico.app/06166293dc6c4e7e856b263de9c79173'}
              target="_blank"
            >
              こちら
            </WebLink>
          </Box>
        </VStack>
      </AlertDialogBody>

      <AlertDialogFooter justifyContent={'center'}>
        <VStack justifyContent={'center'} alignItems={'center'} w={'full'}>
          <LogoutLink />
        </VStack>
      </AlertDialogFooter>
    </AlertDialogContent>
  );
};
