import { PaymentDateCalculator, ProviderAccount } from '@pochico/shared';
import dayjs from 'dayjs';

export const needPayment = (providerAccount: ProviderAccount): boolean => {
  const calc = new PaymentDateCalculator(providerAccount, dayjs());
  return (
    calc.remainFreeDays <= 0 && providerAccount.paymentStatus === 'unregistered'
  );
};

export const getRemainDates = (providerAccount: ProviderAccount) => {
  const calc = new PaymentDateCalculator(providerAccount, dayjs());
  return {
    freeLastDateString: calc.trialLastDate.format('YYYY年M月D日'),
    remain: calc.remainFreeDays,
  };
};
