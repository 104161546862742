import {
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Button,
  HStack,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { MdInfo } from 'react-icons/md';
import { useIsPC } from '../../../hooks/useIsPC';
import { Link } from '../../ui/Link';

/**
 * LINE公式アカウントを持っていない場合のダイアログ
 */
export const LineAttachDialogBodyCreate: React.FC<{
  onClickToBack: () => void;
}> = ({ onClickToBack }) => {
  const isPC = useIsPC();
  return (
    <AlertDialogContent mx={{ base: '16px', md: 0 }}>
      <AlertDialogHeader
        borderBottomWidth={'1px'}
        borderBottomColor={'gray.300'}
      >
        <VStack w={'full'}>
          <Text fontSize={{ base: 'lg', md: 'xl' }} color={'gray.800'}>
            LINE公式アカウントをお持ちでない方
          </Text>
        </VStack>
      </AlertDialogHeader>

      <AlertDialogBody>
        <VStack
          alignItems={'center'}
          height={'full'}
          px={{ base: 0, md: '24px' }}
          py={'32px'}
          spacing={'32px'}
        >
          <Text textAlign={'center'} fontSize={'sm'}>
            「LINE公式アカウントを作成する」からLINE公式アカウントの開設をお願いいたします。
          </Text>
          <Button
            as={Link}
            target={'_blank'}
            rel={'noopener noreferrer'}
            to={'https://www.linebiz.com/jp/service/line-official-account/'}
            size={'lg'}
            variant={'blue-fill'}
          >
            LINE公式アカウントを作成する {!isPC && <br />}
            （別サイトに移動します）
          </Button>
          <VStack
            w={'full'}
            spacing={'16px'}
            p={'16px'}
            borderRadius={'4px'}
            borderColor={'gray.300'}
            borderWidth={'1px'}
          >
            <HStack w={'full'} fontWeight={'bold'}>
              <Icon as={MdInfo} color={'blue.500'} />
              <Text>LINE公式アカウントとは？</Text>
            </HStack>
            <Text fontSize={'sm'} color={'gray.700'}>
              「LINE」上で企業や店舗がアカウントを作り、友だち登録してくれたユーザーに直接情報を届けられるサービスです。
            </Text>
          </VStack>
        </VStack>
      </AlertDialogBody>

      <AlertDialogFooter justifyContent={'center'}>
        <VStack
          spacing={6}
          justifyContent={'center'}
          alignItems={'center'}
          w={'full'}
        >
          <Button as={Link} onClick={onClickToBack} colorScheme={'gray'}>
            前の画面に戻る
          </Button>
        </VStack>
      </AlertDialogFooter>
    </AlertDialogContent>
  );
};
