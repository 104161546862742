/**
 * Use the CSS tab above to style your Element's container.
 */
import './CardSectionStyle.css';

import { CardElement } from '@stripe/react-stripe-js';
import * as stripeJs from '@stripe/stripe-js';
import React, { FC, useState } from 'react';

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
} from '@chakra-ui/react';

type Props = {
  onChangeCompleteStatus: (isCompleted: boolean) => void;
};

const CARD_ELEMENT_OPTIONS: stripeJs.StripeCardElementOptions = {
  hidePostalCode: true,
  style: {
    base: {
      color: '#1A202C', // 'var(--chakra-colors-gray-800)',
      fontFamily: '"Noto Sans JP", "Helvetica Neue", Helvetica, sans-serif',
      fontWeight: '400',
      // fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
      ':-webkit-autofill': {
        color: '#fce883',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const CardSection: FC<Props> = ({ onChangeCompleteStatus }) => {
  const [errorMessage, setErrorMessage] = useState<string>();

  return (
    <FormControl w={'full'} isRequired isInvalid={Boolean(errorMessage)}>
      <VStack w={'full'} alignItems={'flex-start'} spacing={'8px'}>
        <FormLabel>クレジットカード情報</FormLabel>
        <Input
          as={CardElement}
          options={CARD_ELEMENT_OPTIONS}
          onChange={(_event) => {
            // onChangeをCardElementPropsが定義しているが、ChakraのInputPropsと型が違うため、型変換が必要
            const event =
              _event as unknown as stripeJs.StripeCardElementChangeEvent;
            const error = event.error;
            if (error) {
              console.error('[input error]', error.code, error.type);
              setErrorMessage(error.message);
            } else {
              setErrorMessage(undefined);
            }
            onChangeCompleteStatus(event.complete === true);
          }}
        />

        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      </VStack>
    </FormControl>
  );
};

export default CardSection;
