import { Button, Text, useToast } from '@chakra-ui/react';
import { ProviderAccount } from '@pochico/shared';
import React from 'react';
import {
  BookingMenuForConsole as BookingMenu,
  BookingMenuUpdateParams,
} from '../../../../firebase/types';
import {
  useBulkUpdateBookingMenus,
  useFetchBookingMenus,
} from '../../../../hooks/bookingMenus';
import { convertBookingMenuFromDisplayStatusToStatus } from '../../../../providers/dataProvider/bookingMenu';
import { useLoadingOverlayContext } from '../../../ui/LoadingOverlay';

export const BookingMenuStatusToggleButton: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenu: BookingMenu;
  isDisabled?: boolean;
}> = ({ providerAccount, bookingMenu, isDisabled }) => {
  const { refetch: bookingMenusQuery } = useFetchBookingMenus({
    providerAccount,
    enabled: false,
  });
  const updateMutation = useBulkUpdateBookingMenus(providerAccount);
  const overlayContext = useLoadingOverlayContext();
  const toast = useToast();
  const onChangeDisplayStatus = React.useCallback(
    async (
      bookingMenu: BookingMenu,
      newStatus: BookingMenu['displayStatus']
    ) => {
      const result = await bookingMenusQuery();
      if (result.error) {
        return Promise.reject(result.error);
      }
      const bookingMenus = result.data || [];
      try {
        const publishedBookingMenu: BookingMenuUpdateParams =
          convertBookingMenuFromDisplayStatusToStatus({
            ...bookingMenu,
            displayStatus: newStatus, // dataProviderでdisplayStatus -> statusの変換はおこなっている
            displayPriority: 1, // 末尾に移すことにする
          });
        const newOrderedBookingMenus: BookingMenuUpdateParams[] = [
          ...bookingMenus
            .filter((menu) => menu.id !== bookingMenu.id)
            .sort((a, b) => b.displayPriority - a.displayPriority),
          publishedBookingMenu,
        ].map((menu, index) => ({
          ...menu,
          displayPriority: bookingMenus.length - index + 1, // 下書き→公開にしたときにdisplayPriorityを1にしているので2始まりにしておく
        }));
        overlayContext.onOpen();
        await updateMutation.mutateAsync(
          { bookingMenus: newOrderedBookingMenus },
          {
            onSuccess: async () => {
              toast.closeAll();
              toast({
                title: `「${bookingMenu.name}」を${
                  newStatus === 'ON' ? '公開しました' : '下書きに戻しました'
                }`,
                status: 'success',
              });
            },
            onError: (err) => {
              // catchとなぜか重複してしまう
              // toast({
              //   title: `エラーが発生しました ${err}`,
              //   status: 'error',
              // });
            },
            onSettled: () => overlayContext.onClose(),
          }
        );
      } catch (e) {
        toast({
          title: `エラーが発生しました ${e}`,
          status: 'error',
        });
      }
    },
    [bookingMenusQuery, overlayContext, updateMutation, toast]
  );
  return (
    <Button
      isLoading={updateMutation.isPending}
      isDisabled={isDisabled ?? false}
      borderRadius={'4px'}
      px={'8px'}
      py={'4px'}
      size={'sm'}
      onClick={() => {
        onChangeDisplayStatus(
          bookingMenu,
          bookingMenu.status === 'active' ? 'OFF' : 'ON'
        );
      }}
      variant={bookingMenu.status === 'active' ? 'gray-fill' : 'blue-fill'}
    >
      <Text fontSize={'12px'}>
        {bookingMenu.status === 'active' ? '下書きに戻す' : '公開する'}
      </Text>
    </Button>
  );
};
