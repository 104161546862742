import { Box, Button, Image, Link, Text, VStack } from '@chakra-ui/react';
import React, { FC, useEffect } from 'react';

import { logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import { setDocumentTitle } from '../commons/components/setDocumentTitle';
import { Checkmark } from '../components/ui/Checkmark';
import { PageTitle } from '../components/ui/PageTitle';
import { RegistrationLayout } from '../components/ui/RegistrationLayout';
import { WebLink } from '../components/ui/WebLink';
import { useAuthState } from '../context/providerAccount';
import { postBotApi } from '../dataStore/bot';
import { analytics } from '../firebase/firebaseInit';
import { Path } from '../routers/Path';

const AttachComplete: FC = () => {
  const { firebaseUser, providerAccount, initialized } = useAuthState();
  const registerMetadata = React.useCallback(async () => {
    if (!providerAccount || !firebaseUser) {
      return;
    }
    type Params = { [key: string]: string };
    const params = document.cookie.split(';').reduce((acc, cookie) => {
      const [key, value] = cookie.trim().split(' ')[0].split('=');
      // LPを見たときのURLパラメータを`param.`prefix付きでcookieに保存してあるので取り出してAPIに送りつける
      if (key.startsWith('param.')) {
        return { ...acc, [key]: decodeURIComponent(value) };
      } else {
        return acc;
      }
    }, {} as Params);

    if (params && Object.keys(params).length === 0) {
      return;
    }

    const payload = {
      metadata: {
        attachedFirebaseUserId: firebaseUser?.uid,
        ...params,
      },
    };

    return postBotApi(
      `/provider-accounts/${providerAccount.id}`,
      firebaseUser,
      payload
    )
      .then(async (response) => {
        if (response.ok) {
          console.log(
            `successfully wrote metadata. payload: ${JSON.stringify(payload)}`
          );
          return Promise.resolve();
        } else {
          return Promise.reject(response.error);
        }
      })
      .catch((error) => {
        console.error(
          `failed to write metadata. payload: ${JSON.stringify(
            payload
          )}, error: ${error}`
        );
      });
  }, [firebaseUser, providerAccount]);

  setDocumentTitle('LINE連携の完了');

  useEffect(() => {
    if (!providerAccount || !firebaseUser) {
      return;
    }
    const event = {
      firebaseUserId: firebaseUser.uid,
      displayName: firebaseUser.displayName,
      providerAccountId: providerAccount.id,
    };
    setUserId(analytics, firebaseUser.uid, { global: true });
    logEvent(analytics, 'sign_up', event);
    setUserProperties(analytics, {
      providerAccountId: providerAccount.id,
    });
    console.log(`completed sending data to analytics ${JSON.stringify(event)}`);

    // providerAccountのmetadataとして登録しておく
    registerMetadata();
  }, [firebaseUser, providerAccount, registerMetadata]);

  if (!initialized) {
    return <>Loading...</>;
  }

  if (!firebaseUser || !providerAccount) {
    return (
      <RegistrationLayout>
        <Text>登録されていません</Text>
        <VStack spacing={'16px'}>
          <Button
            as={Link}
            w={{ base: 'full', md: '340px' }}
            href={Path.login}
            variant={'blue-fill'}
            size={'md'}
          >
            ログイン
          </Button>

          <WebLink fontSize={'md'} href={Path.signup}>
            新規登録
          </WebLink>
        </VStack>
      </RegistrationLayout>
    );
  }
  return (
    <RegistrationLayout>
      <VStack w={'full'}>
        <Image
          src={'/assets/footer-logo-pochico.png'}
          w={'180px'}
          objectFit={'contain'}
        />
        <PageTitle fontSize={'24px'} color={'gray.700'}>
          LINE連携が完了しました
        </PageTitle>
      </VStack>

      <VStack w={'full'} px={'32px'} spacing={'32px'}>
        <Text fontSize={'md'} textAlign={'center'}>
          さあ、準備が整いました！
          <br />
          以下より管理者ツールに進んで、使い方を確認していきましょう！
        </Text>
        <Box color={'green.400'} w={'140px'} h={'140px'}>
          <Checkmark />
        </Box>

        <VStack w={{ base: 'full', md: '340px' }} spacing={'12px'}>
          <Button
            as={Link}
            href={Path.home}
            variant={'blue-fill'}
            width={'full'}
            size={'lg'}
          >
            管理者ツールへ進む
          </Button>
        </VStack>
      </VStack>
    </RegistrationLayout>
  );
};
export default AttachComplete;
