import {
  Box,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Stack,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  BookingMenu,
  DeadlineSettings,
  RelativeDeadline,
  toDeadline,
} from '@pochico/shared';
import dayjs from 'dayjs';
import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import CONSTANTS from '../../commons/constants';
import { useIsPC } from '../../hooks/useIsPC';

// 受付開始/終了、キャンセル期限の入力
type LimitDateTimeInputProps = {
  bookingMenu?: BookingMenu;
  source: 'bookingStart' | 'bookingEnd' | 'cancelEnd';
  label: string; // フォームのタイトル
  sentence: string; // フォーム内の文章('X日前のHH:MM'のあとに続く文章)
  offDescription: string; // チェックボックスがOFFの時の説明
  showExample: (input: DeadlineSettings) => { time: string; text: string }; // フォーム内の例示する文章
};

const exampleNow = dayjs('2023-03-31 15:00:00+09:00');
const formattedExampleNow = exampleNow.format('M月D日 HH:mm');

export const LimitDateTimeInput: React.FC<{
  bookingMenu?: LimitDateTimeInputProps['bookingMenu'];
  source: LimitDateTimeInputProps['source'];
}> = ({ bookingMenu, source }) => {
  switch (source) {
    case 'bookingStart':
      return (
        <LimitDateTimeInputShared
          bookingMenu={bookingMenu}
          label="予約受付の『開始』日時を設定する"
          source="bookingStart"
          sentence="に予約受付を開始"
          offDescription="メニューを公開した時点から予約が可能になります。"
          showExample={(input) => {
            const deadline = toDeadline(exampleNow, input);
            const time = `${deadline.format(`M月D日 H:mm`)}`;
            return {
              time,
              text: `${formattedExampleNow}の枠が予約できるのは、${time}からです。`,
            };
          }}
        />
      );
    case 'bookingEnd':
      return (
        <LimitDateTimeInputShared
          bookingMenu={bookingMenu}
          label="予約受付の『終了』日時を設定する"
          source="bookingEnd"
          sentence="に予約受付を終了"
          offDescription="メニュー非公開にするまで予約を受け付けます。"
          showExample={(input) => {
            const deadline = toDeadline(exampleNow, input);
            const time = `${deadline.format(`M月D日 H:mm`)}`;
            return {
              time,
              text: `${formattedExampleNow}の枠が予約できるのは、${time}までです。`,
            };
          }}
        />
      );
    case 'cancelEnd':
      return (
        <LimitDateTimeInputShared
          bookingMenu={bookingMenu}
          label="『キャンセル』受付の終了日時を設定する"
          source="cancelEnd"
          sentence="にキャンセル受付を終了"
          offDescription="予約開始時間直前までキャンセルを受け付けます。"
          showExample={(input) => {
            const deadline = toDeadline(exampleNow, input);
            const time = `${deadline.format(`M月D日 H:mm`)}`;
            return {
              time,
              text: `${formattedExampleNow}の予約がキャンセルできるのは、${time}までです。`,
            };
          }}
        />
      );
  }
};

// type: fixed(dayBefore/time)とtype: relative({day,hour,minute}Before)の入力フォームを共通化するためのcomponent
const LimitDateTimeInputShared = (props: LimitDateTimeInputProps) => {
  const { source, label, bookingMenu, sentence, showExample } = props;
  const [visible, setVisible] = React.useState<boolean>(() => {
    return !!bookingMenu?.[source];
  });
  const { control, setValue, getValues } = useFormContext();

  const currentInput: DeadlineSettings = useWatch({ control, name: source });
  const type = currentInput?.type || 'fixed'; // 互換性のためfixedを指定
  const setDefaultValues = React.useCallback(
    (type: DeadlineSettings['type']) => {
      setValue(`${source}.type`, type, { shouldDirty: true });
      // TODO: editの際はすでにある値を使いたい
      if (type === 'fixed') {
        if (
          bookingMenu?.[source]?.type === undefined ||
          bookingMenu?.[source]?.type === 'fixed'
        ) {
          const dayBefore = bookingMenu?.[source]?.dayBefore
            ? bookingMenu[source]?.dayBefore
            : CONSTANTS.DEFAULT_BOOKING_LIMIT_DAY_BEFORE;
          const time = bookingMenu?.[source]?.time
            ? bookingMenu[source]?.time
            : CONSTANTS.DEFAULT_BOOKING_LIMIT_TIME;
          setValue(`${source}.dayBefore`, dayBefore, { shouldDirty: true });
          setValue(`${source}.time`, time, { shouldDirty: true });
        } else {
          setValue(`${source}.time`, undefined, { shouldDirty: true });
        }
      } else {
        if (bookingMenu?.[source]?.type === 'relative') {
          const dayBefore =
            bookingMenu?.[source]?.dayBefore ??
            CONSTANTS.DEFAULT_BOOKING_LIMIT_DAY_BEFORE;
          const hourBefore = bookingMenu?.[source]?.hourBefore ?? 0;
          const minuteBefore = bookingMenu[source]?.minuteBefore ?? 0;
          setValue(`${source}.dayBefore`, dayBefore, { shouldDirty: true });
          setValue(`${source}.hourBefore`, hourBefore, { shouldDirty: true });
          setValue(`${source}.minuteBefore`, minuteBefore, {
            shouldDirty: true,
          });
        } else {
          setValue(`${source}.hourBefore`, 0, { shouldDirty: true });
          setValue(`${source}.minuteBefore`, 0, { shouldDirty: true });
        }
      }
    },
    [bookingMenu, setValue, source]
  );

  const onChange = React.useCallback(() => {
    const newVisible = !visible;
    // チェックボックスがtrueの時だけ入力欄を有効化する
    if (newVisible) {
      setDefaultValues(bookingMenu?.[source]?.type || 'fixed');
    } else {
      setValue(`${source}`, undefined, { shouldDirty: true });
    }
    setVisible(newVisible);
  }, [bookingMenu, setDefaultValues, setValue, source, visible]);
  const example = React.useMemo(
    () => (visible && currentInput ? showExample(currentInput) : undefined),
    [currentInput, showExample, visible]
  );
  const isPC = useIsPC();

  return (
    <VStack w={'full'} spacing={'24px'}>
      <HStack width={'full'} justifyContent={'space-between'} spacing={'4px'}>
        <Text fontWeight={'bold'}>{label}</Text>

        <HStack spacing={'4px'}>
          <Text>{visible ? 'ON' : 'OFF'}</Text>
          <Switch
            defaultChecked={visible}
            checked={visible}
            onChange={onChange}
          />
        </HStack>
      </HStack>
      {visible && (
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={'12px'}
          borderTopWidth={'1px'}
          borderTopColor={'gray.200'}
          pt={'24px'}
          alignItems={'flex-start'}
          w={'full'}
          fontSize={{ base: 'sm', md: 'md' }}
        >
          <RadioGroup
            value={type}
            w={'full'}
            onChange={(value) => {
              setValue(`${source}.type`, value, { shouldDirty: true });
              setDefaultValues(value as 'fixed' | 'relative');
              console.log(`getValues(${source})`, getValues(source));
            }}
          >
            <VStack spacing={'20px'} alignItems={'flex-start'}>
              <VStack spacing={'8px'} alignItems={'flex-start'}>
                <Radio value={'fixed'} size={'lg'}>
                  <Box
                    fontSize={{ base: 'sm', md: 'md' }}
                    whiteSpace={'nowrap'}
                  >
                    指定の時間{sentence}する
                  </Box>
                </Radio>
                {type === 'fixed' && (
                  <Box pl={'24px'}>
                    <FixedDeadlineInput {...props} />
                  </Box>
                )}
              </VStack>
              <VStack spacing={'8px'} alignItems={'flex-start'}>
                <Radio value={'relative'} size={'lg'}>
                  <Box
                    fontSize={{ base: 'sm', md: 'md' }}
                    whiteSpace={'nowrap'}
                  >
                    予約{source === 'cancelEnd' ? '' : '枠'}の◯時間前{sentence}
                    する
                  </Box>
                </Radio>

                {type === 'relative' && (
                  <Box pl={'24px'}>
                    <RelativeDeadlineInput {...props} />
                  </Box>
                )}
              </VStack>
            </VStack>
          </RadioGroup>
          <VStack
            w={'full'}
            borderRadius={'4px'}
            backgroundColor={'gray.50'}
            borderColor={'gray.200'}
            borderWidth={'1px'}
            paddingX={'16px'}
            paddingY={'8px'}
            alignItems={'flex-start'}
            spacing={'4px'}
          >
            {/* <Icon as={MdOutlinePushPin} color={'black'} /> */}
            <Text fontWeight={'bold'} color={'blue.500'}>
              現在の設定
            </Text>
            <Text>例) {example?.text}</Text>
            <HStack w={'full'} pt={'30px'} pb={'16px'}>
              <Slider
                id="slider"
                // defaultValue={5}
                value={
                  source === 'bookingEnd'
                    ? 66
                    : source === 'cancelEnd'
                    ? 50
                    : 33
                }
                min={0}
                max={100}
                colorScheme="teal"
                isReadOnly
                color={'gray.500'}
                fontWeight={'bold'}
                fontSize={'xs'}
                whiteSpace={'nowrap'}
              >
                {source === 'bookingStart' ? (
                  <>
                    <SliderMark value={isPC ? 10 : 5} mt={'8px'}>
                      予約不可
                    </SliderMark>
                    <SliderMark
                      value={isPC ? 65 : 55}
                      mt={'8px'}
                      color={'blue.400'}
                    >
                      予約可
                    </SliderMark>
                    <SliderTrack
                      h={'10px'}
                      backgroundImage={
                        'repeating-linear-gradient(45deg, #90CDF4, #90CDF4 5px, #bee3f8 5px, #bee3f8 10px)'
                      }
                    >
                      <SliderFilledTrack bgColor={'gray.200'} />
                    </SliderTrack>
                  </>
                ) : source === 'bookingEnd' ? (
                  <>
                    <SliderMark value={30} mt="8px" color={'blue.400'}>
                      予約可
                    </SliderMark>
                    <SliderMark value={isPC ? 80 : 74} mt={'8px'}>
                      予約不可
                    </SliderMark>
                    <SliderTrack h={'10px'}>
                      <SliderFilledTrack
                        backgroundImage={
                          'repeating-linear-gradient(45deg, #90CDF4, #90CDF4 5px, #bee3f8 5px, #bee3f8 10px)'
                        }
                      />
                    </SliderTrack>
                  </>
                ) : (
                  <>
                    <SliderMark
                      value={isPC ? 17 : 7}
                      mt={'8px'}
                      color={'blue.400'}
                    >
                      キャンセル可
                    </SliderMark>
                    <SliderMark value={isPC ? 65 : 55} mt={'8px'}>
                      キャンセル不可
                    </SliderMark>
                    <SliderTrack h={'10px'}>
                      <SliderFilledTrack
                        backgroundImage={
                          'repeating-linear-gradient(45deg, #90CDF4, #90CDF4 5px, #bee3f8 5px, #bee3f8 10px)'
                          // 'repeating-linear-gradient(45deg, #FEB2B2, #FEB2B2 5px, #FED7D7 5px, #FED7D7 10px)'
                        }
                      />
                    </SliderTrack>
                  </>
                )}

                <Box position={'relative'}>
                  <SliderThumb
                    borderColor={'gray.300'}
                    borderWidth={'2px'}
                    p={'8px'}
                  >
                    <Box
                      position="absolute"
                      top="-48px"
                      left="50%"
                      transform="translateX(-50%)"
                      bg="gray.700"
                      color="white"
                      p="8px"
                      borderRadius="4px"
                      fontSize="xs"
                      whiteSpace="nowrap"
                      _after={{
                        content: '""',
                        position: 'absolute',
                        bottom: '-24px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        borderWidth: '12px',
                        borderStyle: 'solid',
                        borderColor: 'transparent',
                        borderTopColor: '#2D3748',
                      }}
                    >
                      {example?.time}
                    </Box>
                  </SliderThumb>
                </Box>
              </Slider>
              <VStack
                h={'full'}
                whiteSpace={'nowrap'}
                p={'8px'}
                borderRadius={'4px'}
                borderColor={'gray.200'}
                borderWidth={'1px'}
                bgColor={'white'}
                spacing={0}
                color={'gray.500'}
                fontWeight={'bold'}
              >
                <Box>{exampleNow.format('M月D日')}</Box>
                <Box>{exampleNow.format('HH:mm')}</Box>
              </VStack>
            </HStack>
          </VStack>
        </Stack>
      )}
    </VStack>
  );
};

// 指定の日時の入力
const FixedDeadlineInput = ({ source, sentence }: LimitDateTimeInputProps) => {
  const isPC = useIsPC();
  const { register } = useFormContext();

  return (
    <VStack w={'full'} spacing={'12px'} alignItems={'flex-start'}>
      {isPC ? (
        <HStack
          justifyContent={'center'}
          alignItems={'baseline'}
          spacing={'4px'}
        >
          <Input
            width={'3rem'}
            type={'number'}
            inputMode="numeric"
            placeholder={'5'}
            paddingRight={0}
            min={0}
            max={180}
            {...register(`${source}.dayBefore`, {
              required: true,
              // valueAsNumber: true,
            })}
          />
          <Text>日前の</Text>
          <Input
            width={'8rem'}
            type={'time'}
            placeholder={'12:00'}
            paddingLeft={'8px'}
            paddingRight={0}
            min={0}
            max={180}
            {...register(`${source}.time`, {
              required: false,
            })}
          />
          <Text>{sentence}</Text>
        </HStack>
      ) : (
        <HStack justifyContent={'center'} alignItems={'center'} spacing={'4px'}>
          <VStack alignItems={'flex-start'}>
            <HStack>
              <Input
                width={'3rem'}
                type={'number'}
                inputMode="numeric"
                placeholder={'5'}
                paddingRight={0}
                min={0}
                max={180}
                {...register(`${source}.dayBefore`, {
                  required: true,
                })}
              />
              <Text>日前の</Text>
            </HStack>
            <Input
              width={'7rem'}
              type={'time'}
              placeholder={'12:00'}
              // paddingLeft={'8px'}
              // paddingRight={0}
              min={0}
              max={180}
              {...register(`${source}.time`, {
                required: false,
              })}
            />
          </VStack>
          <Text>{sentence}</Text>
        </HStack>
      )}
    </VStack>
  );
};

// 予約枠の◯時間前の入力
const RelativeDeadlineInput = ({
  source,
  sentence,
}: LimitDateTimeInputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<{ [k in typeof source]: RelativeDeadline }>();
  const error = errors[source];
  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      justifyContent={'center'}
      alignItems={'baseline'}
      spacing={'4px'}
    >
      <HStack spacing={'4px'} alignItems={'center'}>
        <Box whiteSpace={'nowrap'}>
          予約{source === 'cancelEnd' ? '' : '枠'}の
        </Box>
        <FormControl isInvalid={!!error?.dayBefore} width={'5rem'}>
          <HStack>
            <Input
              width={'3rem'}
              type={'number'}
              inputMode="numeric"
              placeholder={'5'}
              paddingRight={0}
              min={0}
              max={180}
              {...register(`${source}.dayBefore`, {
                required: true,
                min: 0,
                max: {
                  value: 180,
                  message: '180日以内で設定してください',
                },
                // valueAsNumber: true,
              })}
            />
            <Text>日</Text>
          </HStack>
          <FormErrorMessage>{error?.dayBefore?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!error?.hourBefore} width={'6rem'}>
          <HStack>
            <Input
              width={'3rem'}
              type={'number'}
              inputMode="numeric"
              placeholder={'0'}
              paddingLeft={'8px'}
              paddingRight={0}
              min={0}
              max={23}
              {...register(`${source}.hourBefore`, {
                required: false,
                min: 0,
                max: 23,
              })}
            />
            <Text>時間</Text>
          </HStack>
          <FormErrorMessage>{error?.hourBefore?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!error?.minuteBefore} width={'6rem'}>
          <HStack>
            <Input
              width={'3rem'}
              type={'number'}
              inputMode="numeric"
              placeholder={'0'}
              paddingLeft={'8px'}
              paddingRight={0}
              min={0}
              max={59}
              {...register(`${source}.minuteBefore`, {
                required: false,
                min: 0,
                max: 59,
              })}
            />
            <Text>分前</Text>
          </HStack>
          <FormErrorMessage>{error?.minuteBefore?.message}</FormErrorMessage>
        </FormControl>
      </HStack>
      <Text>{sentence}</Text>
    </Stack>
  );
};
