import { Button, HStack, Text, VStack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from 'react-icons/md';
import { SpotsCalendarBody } from './SpotCalendarBody';

export const EmptySpotCalendar = ({}) => {
  return (
    <VStack
      // minW={`${cellSize.width * 8}px`}
      w={'full'}
      borderColor={'gray.200'}
      borderWidth={{ base: 0, md: '1px' }}
      borderRadius={4}
      p={{ base: 0, md: '16px' }}
      spacing={4}
      justifyContent={'center'}
      alignItems={'flex-start'}
    >
      <VStack
        w={'fit-content'}
        justifyContent={'center'}
        alignItems={'flex-start'}
        spacing={'20px'}
      >
        <Calendar />
      </VStack>
    </VStack>
  );
};

const Calendar = () => {
  const [weekOffset, setWeekOffset] = React.useState(0);
  const today = React.useMemo(() => dayjs(), []);
  const duration = React.useMemo(() => {
    const start = today.add(weekOffset, 'week');
    const end = today.add(weekOffset + 1, 'week');
    return { start, end };
  }, [today, weekOffset]);

  return (
    <VStack w={'full'} alignItems={'flex-start'} justifyContent={'center'}>
      <HStack w={'full'} justifyContent={'flex-start'} alignItems={'center'}>
        <HStack w={'fit-content'} alignItems={'center'} spacing={'6px'}>
          <Button
            bgColor={'transparent'}
            borderRadius={'6px'}
            size={'sm'}
            onClick={() => setWeekOffset((prev) => prev - 1)}
            px={0}
          >
            <MdOutlineKeyboardArrowLeft size={'24px'} />
          </Button>
          <Button
            bgColor={'transparent'}
            borderRadius={'6px'}
            size={'sm'}
            px={0}
            onClick={() => setWeekOffset((prev) => prev + 1)}
          >
            <MdOutlineKeyboardArrowRight size={'24px'} />
          </Button>
        </HStack>
        <Text fontWeight={'bold'} fontSize={'lg'} textAlign={'center'}>
          {duration.start.format('YYYY年M月')}
        </Text>
      </HStack>
      <SpotsCalendarBody
        providerAccount={undefined as any}
        bookingMenu={undefined as any}
        bookingMenuList={[]}
        spotMap={{}}
        duration={duration}
      />
    </VStack>
  );
};
