import { Box, HStack, Spacer, Spinner } from '@chakra-ui/react';
import React from 'react';
import { useIsPC } from '../../hooks/useIsPC';
import { ConsoleHeader } from './ConsoleHeader';
import {
  ConsoleSidebar,
  ConsoleSidebarContextProvider,
  useConsoleSidebarContext,
} from './ConsoleSidebar';

export const ConsoleLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <ConsoleSidebarContextProvider>
      {/* <VStack
        w={'fit-content'}
        minW={'100vw'}
        // maxW={'100%'}
        h={'full'}
        minH={'100vh'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        backgroundColor={'gray.10'}
        spacing={0}
        p={0}
        m={0}
      > */}
      <ConsoleHeader />
      <Spacer height={'48px'} />
      <ConsoleLayoutInner>{children}</ConsoleLayoutInner>
      {/* </VStack> */}
    </ConsoleSidebarContextProvider>
  );
};

const ConsoleLayoutInner = ({ children }: { children: React.ReactNode }) => {
  const isPC = useIsPC();
  const { isOpen } = useConsoleSidebarContext();
  return (
    <HStack
      spacing={0}
      p={0}
      m={0}
      w={'full'}
      // w={'100vw'}
      // maxW={'100vw'}
      h={'full'}
      alignItems={'flex-start'}
    >
      <ConsoleSidebar />
      {isPC && (
        <Spacer
          paddingLeft={isOpen ? '200px' : '64px'}
          transition={'all 0.5s ease'}
        />
      )}
      <Box
        // width={{ base: '342px', lg: '891px' }}
        // w={'full'}
        w={
          isPC ? (isOpen ? 'calc(100% - 200px)' : 'calc(100% - 64px)') : 'full'
        }
        // h={'full'}
        // paddingX={'16px'}
        // paddingTop={'16px'}
        paddingBottom={'64px'}
        alignSelf={{ base: 'center', lg: 'normal' }}
        justifyContent={'center'}
      >
        <React.Suspense
          fallback={
            <HStack>
              <Spinner />
              loading...
            </HStack>
          }
        >
          {/* <CheckUnpaid /> */}
          <Box w={'full'} h={'full'} bgColor={'white'}>
            {children}
          </Box>
        </React.Suspense>
      </Box>
    </HStack>
  );
};
