import {
  Box,
  HStack,
  Image,
  Stack,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { FC } from 'react';

import { MdArrowForwardIos } from 'react-icons/md';
import { useInquiryUrl } from '../../hooks/useInquiryUrl';
import { useIsPC } from '../../hooks/useIsPC';
import { Link } from './Link';

const FooterLink = ({ to, children }: { to: string; children: string }) => {
  return (
    <Link
      to={to}
      rel="noopener"
      target="_blank"
      w={'full'}
      borderBottomColor={'gray.200'}
      borderBottomWidth={{ base: '1px', md: 0 }}
    >
      <HStack
        px={'20px'}
        py={{ base: '16px', md: '12px' }}
        w={'full'}
        alignItems={'center'}
        spacing={'16px'}
        justifyContent={'space-between'}
        whiteSpace={'nowrap'}
        fontSize={'sm'}
      >
        <Text fontSize={'sm'} fontWeight={'bold'} color={'gray.800'}>
          {children}
        </Text>
        <Box color={'gray.400'} w={'16px'} h={'16px'}>
          <MdArrowForwardIos />
        </Box>
      </HStack>
    </Link>
  );
};

const Footer: FC = () => {
  const inquiryFormUrl = useInquiryUrl();
  const isPC = useIsPC();

  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      width={'full'}
      px={{ base: 0, md: '64px' }}
      py={{ base: 0, md: '32px' }}
      marginTop={'100px'}
      justifyContent={'center'}
      spacing={'48px'}
      divider={isPC ? <StackDivider /> : <></>}
      bgColor={'white'}
    >
      <Stack
        direction={{ base: 'column', md: 'row' }}
        py={{ base: '20px', md: 0 }}
        justifyContent={'center'}
        alignItems={'stretch'}
        w={'full'}
      >
        <Image
          src={'/assets/footer-logo-pochico.png'}
          p={'20px'}
          maxW={'120px'}
          h={'full'}
          objectFit={'contain'}
        />
        <VStack alignItems={'flex-start'} spacing={'8px'}>
          <FooterLink to="https://pochico.app/">
            かんたん予約アプリ『ポチコ』
          </FooterLink>

          <FooterLink to="https://pochico.app/terms">利用規約</FooterLink>
          <FooterLink to="https://pochico.app/transaction_law">
            特定商取引法に基づく表示
          </FooterLink>
        </VStack>
      </Stack>

      <Stack
        direction={{ base: 'column', md: 'row' }}
        justifyContent={'center'}
        alignItems={'stretch'}
        py={{ base: '20px', md: 0 }}
        w={'full'}
      >
        <Image
          src={'/assets/footer-logo-company.png'}
          h={'full'}
          p={'20px'}
          maxW={'200px'}
          objectFit={'contain'}
        />
        <VStack alignItems={'flex-start'} spacing={'8px'}>
          <FooterLink to="https://play-tech.jp">
            株式会社Play Technologies
          </FooterLink>
          <FooterLink to="https://play-tech.jp/privacy-policy">
            プライバシーポリシー
          </FooterLink>
          <FooterLink to={inquiryFormUrl}>お問い合わせ</FooterLink>
        </VStack>
      </Stack>
    </Stack>
  );
};

export default Footer;
