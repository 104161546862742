import { BookingMenu, DerivedSpotStatus, Spot } from '@pochico/shared';

export const spotStatusText = (spotStatus: DerivedSpotStatus) => {
  // if (bookingMenu.status === 'suspended') {
  //   return '予約メニューが下書き中';
  // }
  switch (spotStatus) {
    case 'deleted':
      return '削除済み';
    case 'past':
      return '終了';
    case 'available':
      // if (spot.bookingIds.length >= spot.maxBookings) {
      //   return '満席';
      // }
      return '受付中';
    case 'before_booking_start':
      return '受付開始前';
    case 'after_booking_end':
      return '受付終了';
  }
};

export const isSpotBookable = (
  bookingMenu: BookingMenu,
  spot: Spot,
  spotStatus: DerivedSpotStatus
) => {
  return (
    bookingMenu?.status === 'active' &&
    spot.bookingIds.length < spot.maxBookings &&
    spotStatus === 'available'
  );
};
