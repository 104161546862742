import {
  Box,
  Button,
  Heading,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { FC } from 'react';

import { setDocumentTitle } from '../commons/components/setDocumentTitle';
import { Checkmark } from '../components/ui/Checkmark';
import Footer from '../components/ui/Footer';
import { Link } from '../components/ui/Link';
import { Path } from '../routers/Path';

export const SignUpCompleted: FC = () => {
  setDocumentTitle('会員登録完了');

  return (
    <VStack
      w={'full'}
      bgColor={'gray.100'}
      pt={{ base: '40px', md: '64px' }}
      gap={{ base: '40px', md: '80px' }}
    >
      <Box w={'full'} px={{ base: '16px', md: 0 }}>
        <VStack
          justifyContent={'center'}
          bgColor={'white'}
          alignItems={'center'}
          w={'full'}
          maxW={'600px'}
          mx={'auto'}
          px={0}
          pt={'32px'}
          pb={'40px'}
          spacing={'32px'}
          divider={<StackDivider borderColor={'gray.200'} />}
        >
          <VStack w={'full'}>
            <Heading
              as={'h1'}
              fontSize={'30px'}
              fontWeight={'bold'}
              color={'gray.700'}
            >
              会員登録が完了しました🎉
            </Heading>
          </VStack>

          <VStack w={'full'} px={'32px'} spacing={'32px'}>
            <Text fontSize={'sm'} color={'gray.600'}>
              ポチコの管理者ツールにログインできるようになりました！
            </Text>
            <Box color={'green.400'} w={'140px'} h={'140px'}>
              <Checkmark />
            </Box>

            <Button
              as={Link}
              to={Path.home}
              w={{ base: 'full', md: '340px' }}
              variant={'blue-fill'}
              size={'md'}
            >
              ポチコ管理者ツールへ
            </Button>
          </VStack>
        </VStack>
      </Box>
      <Footer />
    </VStack>
  );
};
