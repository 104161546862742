import {
  Box,
  BoxProps,
  Button,
  Flex,
  HStack,
  StyleProps,
  SystemProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import { BookingMenu, ProviderAccount, range } from '@pochico/shared';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import React from 'react';
import { MdClose, MdOutlineCircle } from 'react-icons/md';
import { DisplayBooking, DisplaySpot } from '../../../../firebase/types';
import { useIsPC } from '../../../../hooks/useIsPC';
import { useSpotCellDialog } from '../useSpotCellDialog';

export type SpotCellData = {
  spots: DisplaySpot[];
  bookings: DisplayBooking[];
};
export type SpotMap = {
  [date: string]: {
    [hour: string]: SpotCellData;
  };
};
const scrollbarWidth = '16px';
const calendarCellSize = {
  browser: {
    width: 128,
    height: 45,
  },
  mobile: {
    width: 48,
    height: 32,
  },
};
const calendarRowHeaderWidth = {
  browser: '64px',
  mobile: '52px',
};
export const useCalendarCellStyle = (): {
  calendarCellStyle: StyleProps;
  cellSize: { width: number; height: number };
} => {
  const isPC = useIsPC();
  const key = isPC ? 'browser' : 'mobile';

  const cellSize = calendarCellSize[key];
  const calendarCellStyle: SystemProps = {
    width: `${cellSize.width}px`,
    height: `${cellSize.height}px`,
    fontSize: isPC ? 'sm' : 'xs',
    p: 0,
    borderRadius: 0,
    borderWidth: '1px',
    borderColor: 'gray.200',
    boxSizing: 'border-box',
  };
  return {
    calendarCellStyle,
    cellSize,
  };
};

const CalendarRowHeader: React.FC = () => {
  const { calendarCellStyle } = useCalendarCellStyle();
  const isPC = useIsPC();
  return (
    <VStack
      w={calendarRowHeaderWidth[isPC ? 'browser' : 'mobile']}
      spacing={0}
      fontSize={calendarCellStyle.fontSize}
      paddingBottom={'0.4em'}
    >
      {range(0, 24).map((hour) => {
        return (
          <Box
            key={`calendar-column-header-${hour}`}
            h={calendarCellStyle.height}
            w={calendarCellStyle.width}
            textAlign={'center'}
            marginTop={'-0.4em'}
          >
            {hour}:00
          </Box>
        );
      })}
    </VStack>
  );
};

export const SpotsCalendarBody: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenu: BookingMenu;
  bookingMenuList: BookingMenu[];
  spotMap: SpotMap;
  duration: { start: dayjs.Dayjs; end: dayjs.Dayjs };
}> = ({ providerAccount, bookingMenu, bookingMenuList, spotMap, duration }) => {
  const { calendarCellStyle, cellSize } = useCalendarCellStyle();
  const onClickCell = useSpotCellDialog({
    providerAccount,
    bookingMenu,
    bookingMenuList,
  });
  const ref = React.useRef<HTMLDivElement>(null);
  const days = React.useMemo(() => {
    const days: dayjs.Dayjs[] = [];
    let current = duration.start;
    while (current.isBefore(duration.end)) {
      days.push(current);
      current = current.add(1, 'day');
    }
    return days;
  }, [duration]);

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }
    ref.current.scrollTo({
      top: (dayjs().hour() - 1) * cellSize.height,
    });
  }, [cellSize.height]);
  const todayString = React.useMemo(() => dayjs().format('MM/DD'), []);
  const isPC = useIsPC();

  return (
    <VStack
      w={'full'}
      spacing={0}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
    >
      <HStack w={'full'} spacing={0}>
        <Box w={calendarRowHeaderWidth[isPC ? 'browser' : 'mobile']} />
        {days.map((day, i) => {
          const dateString = day.format('MM/DD');
          const dayString = day.format('ddd');
          return (
            <Cell
              key={`calendar-header-${i}`}
              w={calendarCellStyle.width}
              bgColor={dateString === todayString ? 'blue.100' : undefined}
            >
              <VStack alignItems={'center'} spacing={0}>
                <Text>{dateString}</Text>
                <Text
                  color={
                    dayString === '土'
                      ? 'blue.400'
                      : dayString === '日'
                      ? 'red.400'
                      : 'black'
                  }
                >
                  {dayString}
                </Text>
              </VStack>
            </Cell>
          );
        })}
        <Flex flexGrow={1} />
        {/* {isPC && <Box w={scrollbarWidth} />} */}
      </HStack>
      <HStack
        w={'full'}
        alignItems={'flex-start'}
        spacing={0}
        h={`${cellSize.height * 12}px`}
        ref={ref}
        overflowY={'auto'}
        // boxSizing={'content-box'}
        // border={'1px solid red'}
        sx={{
          scrollbarColor: 'gray white',
          scrollbarWidth: scrollbarWidth,
          '&::-webkit-scrollbar': {
            width: scrollbarWidth,
          },
        }}
      >
        <CalendarRowHeader />

        {days.map((day, i) => {
          const key = `calendar-day-${i}`;
          return (
            <VStack
              key={key}
              w={calendarCellStyle.width}
              spacing={0}
              // flexGrow={1}
              borderWidth={'1px'}
              borderColor={'gray.100'}
              // boxSizing={'content-box'}
            >
              {range(0, 24).map((hour) => {
                const data: SpotCellData | undefined =
                  spotMap[day.format('YYYY-MM-DD')]?.[
                    String(hour).padStart(2, '0')
                  ];
                const cellKey = `calendar-cell-${i}-${hour}`;
                const spots = data?.spots || [];
                const available = spots.some(
                  (s) => s.bookingIds.length !== s.maxBookings
                );
                return (
                  <Cell w={'full'} key={cellKey}>
                    <Button
                      {...calendarCellStyle}
                      // w={calendarCellStyle.width}
                      flexGrow={1}
                      // w={'full'}
                      marginX={'-2px'}
                      variant={
                        spots.length > 0
                          ? available
                            ? 'blue-fill'
                            : 'gray-fill'
                          : 'white-selectable'
                      }
                      p={0}
                      borderRadius={0}
                      onClick={() => {
                        onClickCell({ date: day, hour, data });
                      }}
                    >
                      {spots.length > 0 &&
                        (available ? (
                          <MdOutlineCircle size={'20px'} />
                        ) : (
                          <MdClose size={'20px'} />
                        ))}
                    </Button>
                  </Cell>
                );
              })}
            </VStack>
          );
        })}
      </HStack>
    </VStack>
  );
};

const Cell: React.FC<BoxProps> = ({ children, ...rest }) => {
  const { calendarCellStyle } = useCalendarCellStyle();
  return (
    <Flex
      justifyContent={'center'}
      boxSizing={'border-box'}
      alignItems={'center'}
      // flexGrow={1}
      {...calendarCellStyle}
      {...rest}
    >
      {children}
    </Flex>
  );
};
