import { Text, VStack } from '@chakra-ui/react';
import { BookingMenu, DeadlineSettings } from '@pochico/shared';
import * as React from 'react';

type LimitDateTimeProps = {
  bookingMenu: BookingMenu;
  source: 'bookingStart' | 'bookingEnd' | 'cancelEnd';
  label: string;
  description: (input: DeadlineSettings) => string;
};
export const LimitDateTime: React.FC<{
  bookingMenu: LimitDateTimeProps['bookingMenu'];
  source: LimitDateTimeProps['source'];
}> = ({ bookingMenu, source }) => {
  switch (source) {
    case 'bookingStart':
      return (
        <LimitDateTimeShared
          bookingMenu={bookingMenu}
          source="bookingStart"
          label={'予約受付の『開始』日時'}
          description={(input) => {
            if (input.type === 'relative') {
              return `${input.dayBefore} 日 ${input.hourBefore} 時間 ${input.minuteBefore} 分前から予約受付を開始する`;
            } else {
              return `${input.dayBefore} 日前${
                input.time ? `の ${input.time} ` : ''
              }から予約受付を開始する`;
            }
          }}
        />
      );
    case 'bookingEnd':
      return (
        <LimitDateTimeShared
          bookingMenu={bookingMenu}
          source="bookingEnd"
          label={'予約受付の『終了』日時'}
          description={(input) => {
            if (input.type === 'relative') {
              return `${input.dayBefore} 日 ${input.hourBefore} 時間 ${input.minuteBefore} 分前に予約受付を終了する`;
            } else {
              return `${input.dayBefore} 日前${
                input.time ? `の ${input.time} ` : ''
              }に予約受付を終了する`;
            }
          }}
        />
      );
    case 'cancelEnd':
      return (
        <LimitDateTimeShared
          bookingMenu={bookingMenu}
          source="cancelEnd"
          label={'『キャンセル』受付の終了日時'}
          description={(input) => {
            if (input.type === 'relative') {
              return `${input.dayBefore} 日 ${input.hourBefore} 時間 ${input.minuteBefore} 分前までキャンセルを受け付ける`;
            } else {
              return `${input.dayBefore} 日前${
                input.time ? `の ${input.time} ` : ''
              }までキャンセルを受け付ける`;
            }
          }}
        />
      );
  }
};

const LimitDateTimeShared = (props: LimitDateTimeProps) => {
  const { source, bookingMenu, label, description } = props;
  const value = bookingMenu[source];

  return (
    <VStack
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      width={'full'}
    >
      <Text fontWeight={'bold'}>{label}</Text>
      <Text>{value ? description(value) : '設定無し'}</Text>
    </VStack>
  );
};
