import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

export const RequiredIndicator = (props: BoxProps) => {
  return (
    <Box
      color={'red.800'}
      display={'inline'}
      bgColor={'red.100'}
      fontSize={'xs'}
      px={'8px'}
      py={0}
      h={'20px'}
      borderRadius={'2px'}
      gap={'6px'}
      ml={'4px'}
      {...props}
    >
      必須
    </Box>
  );
};
