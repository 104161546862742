import {
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  ProviderAccount,
  SignUpSurveyResponse,
  getRandomString,
} from '@pochico/shared';
import React from 'react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useAuthState } from '../../../context/providerAccount';
import { paymentRelatedPaths } from '../../../routers/Path';
import { useDialogDispatcher } from '../../ui/Dialog';
import {
  SignUpSurveyResponseCheckResult,
  useSignUpSurveyResponseCheckQuery,
  useSignUpSurveyResponseMutation,
} from './hooks';

export const SignUpSurvey = () => {
  const { openSurveyDialog } = useSignUpSurvey();
  React.useEffect(() => openSurveyDialog(), [openSurveyDialog]);
  return <></>;
};

const useSignUpSurvey = () => {
  const { openDialog, isOpen } = useDialogDispatcher();
  const { providerAccount } = useAuthState();
  const check = useSignUpSurveyResponseCheckQuery(providerAccount);
  const location = useLocation();
  const open = React.useCallback(() => {
    if (
      paymentRelatedPaths.includes(location.pathname) ||
      isOpen ||
      !providerAccount ||
      check.isFetching
    ) {
      return;
    }
    if (!check.data || check.data.done) {
      // クエリ失敗したら出さない
      return;
    }

    return openDialog({
      size: 'xl',
      body: (
        <DialogBody
          providerAccount={providerAccount}
          checkResult={check.data}
        />
      ),
      closeOnOverlayClick: false,
    });
  }, [
    check.data,
    check.isFetching,
    isOpen,
    location.pathname,
    openDialog,
    providerAccount,
  ]);

  return {
    openSurveyDialog: open,
  };
};

const DialogBody: React.FC<{
  providerAccount: ProviderAccount;
  checkResult: SignUpSurveyResponseCheckResult;
}> = ({ providerAccount, checkResult: { isNewAccount } }) => {
  const { closeDialog } = useDialogDispatcher();
  const { mutation, onDenied } =
    useSignUpSurveyResponseMutation(providerAccount);
  const form = useForm<SignUpSurveyResponse>({
    defaultValues: {
      id: getRandomString(15),
      providerAccountId: providerAccount.id,
      type: 'signUpSurvey',
      usageStyle: [],
      howYouFind: undefined,
    },
  });
  const {
    register,
    setValue,
    formState: { errors, isValid },
    control,
    handleSubmit,
  } = form;
  const usageStyle = useWatch({ control, name: 'usageStyle' });
  const isButtonDisabled = React.useMemo(() => {
    if (!isValid) {
      return true;
    }
    if (isNewAccount && usageStyle.length === 0) {
      return true;
    }
    return false;
  }, [isNewAccount, isValid, usageStyle.length]);
  const [industry, howYouFind] = useWatch({
    control,
    name: ['industry', 'howYouFind'],
  });
  const [industryInput, setIndustryInput] = React.useState('');
  const [howYouFindInput, setHowYouFindInput] = React.useState('');

  const onSubmit = React.useCallback(
    (response: SignUpSurveyResponse) => {
      return mutation.mutateAsync(
        {
          providerAccount,
          response: {
            ...response,
            industry:
              industry === 'その他' ? `その他 - ${industryInput}` : industry,
            howYouFind:
              howYouFind === 'その他'
                ? `その他 - ${howYouFindInput}`
                : howYouFind || '',
          },
        },
        {
          onSuccess: () => {
            closeDialog();
            // window.location.reload();
          },
        }
      );
    },
    [
      closeDialog,
      howYouFind,
      howYouFindInput,
      industry,
      industryInput,
      mutation,
      providerAccount,
    ]
  );

  return (
    <AlertDialogContent
      width={{ base: 'full', md: '600px' }}
      overflowY={'auto'}
    >
      <AlertDialogHeader>
        <Text fontSize={'lg'} fontWeight="bold">
          ご利用アンケート
        </Text>
      </AlertDialogHeader>
      <AlertDialogCloseButton onClick={onDenied} />

      <AlertDialogBody w={'full'}>
        <FormProvider {...form}>
          <form
            id={'survey-form'}
            onSubmit={handleSubmit(onSubmit)}
            style={{
              width: '100%',
            }}
          >
            <VStack
              w={'full'}
              justifyContent={'center'}
              alignItems={'flex-start'}
              py={'8px'}
              spacing={'20px'}
            >
              <Text fontSize={'md'} fontWeight="bold">
                あなたへの適切なサポートをさせていただくため、かんたんなアンケートにご協力ください。
              </Text>
              <VStack w={'full'} alignItems={'flex-start'} spacing={'16px'}>
                <FormControl isRequired isInvalid={!!errors.industry}>
                  <FormLabel>業種を教えて下さい</FormLabel>
                  <Select
                    w={'full'}
                    placeholder={'選択してください'}
                    {...register('industry', {
                      required: true,
                    })}
                  >
                    <option value={'医療、福祉'}>医療、福祉</option>
                    <option value={'美容、エステ'}>美容、エステ</option>
                    <option value={'フィットネス、ダンス'}>
                      フィットネス、ダンス
                    </option>
                    <option value={'生活関連サービス'}>生活関連サービス</option>
                    <option value={'教育、スクール'}>教育、スクール</option>
                    <option value={'ペット、動物'}>ペット、動物</option>
                    <option value={'宿泊'}>宿泊</option>
                    <option value={'飲食サービス'}>飲食サービス</option>
                    <option value={'小売'}>小売</option>
                    <option value={'運輸'}>運輸</option>
                    <option value={'情報通信'}>情報通信</option>
                    <option value={'その他'}>その他</option>
                  </Select>
                  {industry === 'その他' && (
                    <Input
                      type={'text'}
                      my={'8px'}
                      placeholder={'ゲーム業界'}
                      isRequired
                      value={industryInput}
                      onChange={(e) => {
                        const value = e.target.value;
                        setIndustryInput(value);
                      }}
                    />
                  )}
                  <FormErrorMessage>
                    {errors.industry?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={!!errors?.staffCount}>
                  <FormLabel>何人でポチコを使いますか？</FormLabel>
                  <Select
                    {...form.register('staffCount', {
                      required: true,
                    })}
                    placeholder={'選択してください'}
                    bg={'white'}
                    w={'full'}
                  >
                    <option value={'1人'}>1人</option>
                    <option value={'2~3人'}>2~3人</option>
                    <option value={'4人以上'}>4人以上</option>
                  </Select>
                  <FormErrorMessage>
                    {errors?.staffCount?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  isRequired
                  isInvalid={!!errors?.bookingCountPerWeek}
                >
                  <FormLabel>1週間にどのくらい予約を受け付けますか？</FormLabel>
                  <Select
                    {...form.register('bookingCountPerWeek', {
                      required: true,
                    })}
                    placeholder={'選択してください'}
                    bg={'white'}
                    w={'full'}
                  >
                    <option value={'0~9件'}>0~9件</option>
                    <option value={'10~99件'}>10~99件</option>
                    <option value={'100件以上'}>100件以上</option>
                  </Select>
                  <FormErrorMessage>
                    {errors?.bookingCountPerWeek?.message}
                  </FormErrorMessage>
                </FormControl>

                {isNewAccount && (
                  <>
                    <FormControl isInvalid={!!errors?.usageStyle}>
                      <FormLabel>あてはまるものをお選びください</FormLabel>
                      <Controller
                        name="usageStyle"
                        control={control}
                        render={({ field: { ref: _, ...field } }) => {
                          return (
                            <CheckboxGroup {...field}>
                              <VStack
                                w={'full'}
                                alignItems={'flex-start'}
                                spacing={2}
                              >
                                {[
                                  'はじめて予約システムを使う',
                                  '他のシステムから移行する',
                                  '電話での予約受付と併用する',
                                  'スマートフォン/タブレットのみで使う',
                                  '期間限定の利用',
                                  'どれにもあてはまらない',
                                ].map((option) => {
                                  return (
                                    <Checkbox
                                      key={option}
                                      value={option}
                                      name="elements"
                                    >
                                      {option}
                                    </Checkbox>
                                  );
                                })}
                              </VStack>
                            </CheckboxGroup>
                          );
                        }}
                      />
                      <FormErrorMessage>
                        {errors?.usageStyle?.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={!!errors.howYouFind}>
                      <FormLabel>なにでポチコを知りましたか？</FormLabel>
                      <Select
                        w={'full'}
                        placeholder={'選択してください'}
                        {...register('howYouFind', {
                          required: true,
                        })}
                      >
                        <option value={'LINEマーケットプレイス(拡張機能)から'}>
                          LINEマーケットプレイス(拡張機能)から
                        </option>
                        <option value={'Instagramから'}>Instagramから</option>
                        <option value={'Facebookから'}>Facebookから</option>
                        <option value={'知り合いの紹介から'}>
                          知り合いの紹介から
                        </option>
                        <option value={'その他'}>その他</option>
                      </Select>
                      {howYouFind === 'その他' && (
                        <Input
                          type={'text'}
                          my={'8px'}
                          placeholder={'チラシで見た'}
                          isRequired
                          value={howYouFindInput}
                          onChange={(e) => {
                            const value = e.target.value;
                            setHowYouFindInput(value);
                          }}
                        />
                      )}
                      <FormErrorMessage>
                        {errors.howYouFind?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </>
                )}
              </VStack>
            </VStack>
          </form>
        </FormProvider>
      </AlertDialogBody>

      <AlertDialogFooter justifyContent={'center'}>
        <VStack w={'full'} spacing={6} alignItems={'flex-end'}>
          <Button
            // w={'full'}
            variant={'blue-fill'}
            type={'submit'}
            form={'survey-form'}
            isLoading={mutation.isPending}
            isDisabled={isButtonDisabled}
          >
            保存する
          </Button>
        </VStack>
      </AlertDialogFooter>
    </AlertDialogContent>
  );
};
