import {
  Box,
  Center,
  Flex,
  Heading,
  Spacer,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { AttachErrorCode, attachErrorCodes } from '@pochico/shared';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { setDocumentTitle } from '../commons/components/setDocumentTitle';
import { ErrorCode } from '../components/ui/ErrorCode';
import Footer from '../components/ui/Footer';
import { WebLink } from '../components/ui/WebLink';
import { useAuthState } from '../context/providerAccount';
import { fetchCustomerPortalURL } from '../dataStore/paymentRepository';
import { Path } from '../routers/Path';

export const AttachError: React.FC = () => {
  setDocumentTitle('LINE連携に失敗');
  const { providerAccount, firebaseUser, clear } = useAuthState();
  // URLパラメータの`errorCode`からエラーの内容を決定する
  const [errorElement, setErrorElement] = React.useState<
    JSX.Element | string | undefined
  >(undefined);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  // awaitしない
  React.useEffect(() => {
    (async () => {
      switch (params.get('errorCode') as AttachErrorCode) {
        case attachErrorCodes.timeout:
          return 'タイムアウトが発生しました。時間を空けてもう一度ログインしてからお試しください。';

        case attachErrorCodes.unauthenticated:
          return '認証処理に失敗しました。時間を空けてもう一度ログインしてからお試しください。';

        case attachErrorCodes.already_attached:
          return 'LINE公式アカウントがすでにポチコと連携済みです。ログインページから再度ログインしてお試しください。';

        case attachErrorCodes.already_connected:
          return '複数のLINE公式アカウントを同じメールアドレスで登録することはできません。新規登録ページから別のメールアドレスで新しくアカウントを作成してください。';

        case attachErrorCodes.user_cancelled_authorize:
          return '操作が中断されました。再度ログインしてからLINE連携をやり直してください';

        case attachErrorCodes.unpaid_invoice:
          const portalUrl = await (async () => {
            if (firebaseUser && providerAccount) {
              return fetchCustomerPortalURL(firebaseUser, providerAccount);
            }
            return;
          })();
          const text =
            '支払い期日が過ぎている請求があります。先に支払いを済ませてからLINE連携をおこなってください。';
          return (
            <>
              {text}
              <br />
              {portalUrl ? (
                <>
                  <WebLink href={portalUrl} target={'_blank'} rel={'noopener'}>
                    支払いポータル
                  </WebLink>
                  を確認してください
                </>
              ) : (
                <Spinner />
              )}
            </>
          );

        default:
          const error =
            (params.get('errorCode') || '') + (params.get('error') || '');
          return `予期しないエラーが発生しました。時間を空けてもう一度ログインしてからお試しください。エラー: ${error}`;
      }
    })().then(setErrorElement);
  }, [firebaseUser, location.search, providerAccount]);

  if (!errorElement) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }
  console.log(errorElement);

  return (
    <Box paddingY={'16px'} paddingX={'16px'}>
      <Flex
        direction={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        maxWidth={'620px'}
        marginX={'auto'}
        textAlign={'center'}
      >
        <Spacer mt={10} />
        <Heading>
          {GLOBAL_CONFIG.SERVICE_NAME.FULL_NAME_JP}
          <br />
          LINE連携に失敗しました
        </Heading>
        <Spacer mt={5} />

        <Box py={10} fontSize={'lg'}>
          <ErrorCode
            errorCode={params.get('errorCode') as AttachErrorCode}
            error={params.get('error') || undefined}
          />
        </Box>

        {providerAccount || firebaseUser ? (
          <Box
            w={'full'}
            alignItems={'left'}
            textAlign={'left'}
            paddingBottom={4}
          >
            {providerAccount && (
              <Text>LINE公式アカウント: {providerAccount.displayName}</Text>
            )}
            {firebaseUser && (
              <Text>ログイン中のメールアドレス: {firebaseUser.email}</Text>
            )}
          </Box>
        ) : (
          <></>
        )}

        <VStack spacing={3} alignItems={'flex-start'} textAlign={'left'}>
          <Text fontSize="small">
            ポチコのヘルプページは
            <WebLink
              href="https://help.pochico.app/36e5b4f58245418a921ce7ee784728cf"
              target="_blank"
              rel="noopener noreferrer"
            >
              こちら
            </WebLink>
          </Text>
          <Text fontSize="small">
            新規登録は
            <WebLink onClick={clear} href={Path.signup}>
              こちら
            </WebLink>
          </Text>
          <Text fontSize="small">
            ログインは
            <WebLink onClick={clear} href={Path.login}>
              こちら
            </WebLink>
          </Text>
          <Text fontSize="small">
            お困りの場合は
            <WebLink href="mailto:support-pochico@play-tech.jp">
              {' support-pochico@play-tech.jp '}
            </WebLink>
            までお気軽にご連絡ください。その際は上記に表示されているエラー文を添えていただけると幸いです。
          </Text>
        </VStack>
      </Flex>
      <Footer />
    </Box>
  );
};
