import { Button, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import {
  MdHelp,
  MdInfo,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from 'react-icons/md';
import { Link } from '../../ui/Link';

export const TroubleShooting: React.FC = () => {
  const [expand, setExpand] = React.useState(false);
  return (
    <VStack w={'full'} spacing={'8px'}>
      <Button
        py={'12px'}
        variant={'transparent-clickable'}
        onClick={() => setExpand(!expand)}
      >
        <HStack pl={'16px'}>
          <Text fontSize={'sm'} textDecoration={'underline'} color={'gray.500'}>
            連携がうまくいかない場合
          </Text>
          <Icon
            as={expand ? MdKeyboardArrowUp : MdKeyboardArrowDown}
            color={'gray.400'}
          />
        </HStack>
      </Button>
      {expand && (
        <VStack
          w={'full'}
          spacing={'16px'}
          p={'16px'}
          borderRadius={'4px'}
          borderColor={'gray.300'}
          borderWidth={'1px'}
        >
          <HStack w={'full'} fontWeight={'bold'}>
            <Icon as={MdInfo} color={'yellow.400'} />
            <Text>LINE公式アカウント連携がうまく行かない場合</Text>
          </HStack>
          <Text fontSize={'sm'} color={'gray.700'}>
            ポチコスタッフが連携をサポートします。用語がわからない/初めてなので不安がある/連携に失敗してしまった等ありましたらお気軽にご相談ください。
          </Text>
          <HStack w={'full'} justifyContent={'flex-end'}>
            <Button
              as={Link}
              to={'https://help.pochico.app/47b94f9e12304cf9b88bbb95c07dbff1'}
              target={'_blank'}
              variant={'gray'}
              size={{ base: 'sm', md: 'md' }}
            >
              <HStack>
                <Icon as={MdHelp} />
                <Text>ヘルプを見る</Text>
              </HStack>
            </Button>
            <Button
              as={Link}
              variant={'white-blue'}
              size={{ base: 'sm', md: 'md' }}
              to={
                'https://docs.google.com/forms/d/e/1FAIpQLSdXxA91PSNGvQx5K079MMPOzwb_vrD7h79z23bYOOfmRBXB7w/viewform'
              }
              target={'_blank'}
            >
              <HStack>
                <Text>連携代行を依頼する</Text>
              </HStack>
            </Button>
          </HStack>
        </VStack>
      )}
    </VStack>
  );
};
