import {
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ProviderAccount } from '@pochico/shared';
import { useMutation } from '@tanstack/react-query';
import CONSTANTS from '../../../../commons/constants';
import { SpotCreateParams } from '../../../../firebase/types';
import { useCreateSpot } from '../../../../hooks/spots';
import { useDialogDispatcher } from '../../../ui/Dialog';
import { FixedRight } from '../../../ui/Fixed';
import { RightArrowWhite } from '../../../ui/RightArrow';
import {
  OnboardingDialogOptions,
  useOnboardingProgress,
} from './useOnboardingProgress';

export const useOnboardingSpotDialog = ({
  providerAccount,
  onCompleted,
  options,
}: {
  providerAccount: ProviderAccount;
  onCompleted: () => void;
  options?: OnboardingDialogOptions;
}) => {
  const {
    loading: progressLoading,
    onboardingProgress,
    updateProgress,
  } = useOnboardingProgress(options);
  const { isOpen, openDialog, closeDialog } = useDialogDispatcher();
  const toast = useToast();
  const mutation = useCreateSpot(providerAccount);
  const onSubmit = React.useCallback(
    (input: SpotCreateParams) => {
      if (!providerAccount) {
        return;
      }
      return mutation
        .mutateAsync(input)
        .then((res) => {
          return updateProgress('createSpot');
        })
        .then(() => {
          toast({
            title: '予約枠の作成が完了しました',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          closeDialog();
          onCompleted();
        })
        .catch((e) => {
          toast({
            title: `予約枠の作成に失敗しました. ${e}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        });
    },
    [providerAccount, mutation, updateProgress, toast, closeDialog, onCompleted]
  );

  const openOnboardingSpotDialog = React.useCallback(() => {
    if (isOpen || progressLoading) {
      return;
    }
    if (onboardingProgress.createSpot) {
      onCompleted();
      return;
    }
    openDialog({
      size: '3xl',
      body: <DialogBody onSubmit={onSubmit} />,
    });
  }, [
    isOpen,
    progressLoading,
    onboardingProgress?.createSpot,
    openDialog,
    onSubmit,
    onCompleted,
  ]);

  return {
    loading: progressLoading,
    openOnboardingSpotDialog,
  };
};

const DialogBody: React.FC<{
  onSubmit: (input: SpotCreateParams) => void;
}> = ({ onSubmit }) => {
  const form = useForm<SpotCreateParams & { startTime: string }>({
    defaultValues: {
      bookingMenuId: CONSTANTS.DEFAULT_BOOKING_MENU_ID,
      date: dayjs().add(7, 'd').format('YYYY-MM-DD'),
      startTime: '10:00',
      startTimeHour: 10,
      startTimeMinute: 0,
    },
  });
  const disabled = !form.formState.isValid;

  const mutation = useMutation({
    mutationFn: async (input: SpotCreateParams) => {
      const data = {
        ...input,
      };
      return onSubmit(data);
    },
  });

  return (
    <AlertDialogContent marginX={'16px'}>
      <AlertDialogHeader>
        <VStack alignItems={'center'} justifyContent={'center'}>
          <Text fontSize="md" color={'blue.600'}>
            [チュートリアル STEP2/4]
          </Text>
          <Text fontSize="lg" fontWeight="bold">
            予約枠を作成してみよう
          </Text>
        </VStack>
      </AlertDialogHeader>
      <AlertDialogCloseButton />

      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit((input) => mutation.mutateAsync(input))}
          style={{
            width: '100%',
          }}
        >
          <AlertDialogBody>
            <Stack
              direction={{ base: 'column', md: 'row' }}
              w={'full'}
              justifyContent={'center'}
              alignItems={'center'}
              spacing={'16px'}
            >
              <VStack
                alignItems={'center'}
                w={{ base: 'full', md: '400px' }}
                spacing={4}
              >
                <FormControl
                  isRequired={false}
                  isInvalid={!!form.formState.errors.date}
                >
                  <FormLabel>予約日</FormLabel>
                  <Input
                    maxWidth={'full'}
                    type={'date'}
                    {...form.register('date', {
                      required: true,
                      validate: (value: string) => {
                        if (dayjs(value).isBefore(dayjs())) {
                          return '予約枠は今日以降の日付を選択してください';
                        }
                        return undefined;
                      },
                    })}
                  />
                  <FormErrorMessage>
                    {form.formState.errors.date?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={!!form.formState.errors?.startTime}
                >
                  <FormLabel>開始時間</FormLabel>
                  <Input
                    w={'full'}
                    {...form.register('startTime', {
                      validate: (value) => {
                        if (!value) {
                          return '入力してください';
                        }
                        return undefined;
                      },
                      onChange: (e) => {
                        const [hour, minute] = String(e.target.value).split(
                          ':'
                        );
                        form.setValue('startTimeHour', Number(hour));
                        form.setValue('startTimeMinute', Number(minute));
                      },
                    })}
                    type={'time'}
                    bg={'white'}
                  />
                  <FormErrorMessage>
                    {form.formState.errors?.startTime?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  isRequired={true}
                  isInvalid={!!form.formState.errors.maxBookings}
                >
                  <FormLabel>この時間に予約できる件数</FormLabel>
                  <InputGroup>
                    <Input
                      type={'number'}
                      width={'full'}
                      {...form.register('maxBookings', {
                        valueAsNumber: true,
                        required: true,
                        min: {
                          value: 1,
                          message: '1以上の数値を入力してください',
                        },
                      })}
                    />
                    <InputRightAddon children="件" />
                  </InputGroup>
                  <FormErrorMessage>
                    {form.formState.errors.maxBookings?.message}
                  </FormErrorMessage>
                </FormControl>
              </VStack>
            </Stack>
          </AlertDialogBody>

          <AlertDialogFooter justifyContent={'center'}>
            <VStack w={'full'} alignItems={'flex-end'}>
              <Button
                whiteSpace={'nowrap'}
                variant={'blue-fill'}
                w={{ base: 'full', md: '220px' }}
                type={'submit'}
                isLoading={mutation.isPending}
                isDisabled={disabled}
              >
                予約時間の枠を設定する
                <FixedRight>
                  <RightArrowWhite />
                </FixedRight>
              </Button>
              <Text>※設定が完了してもLINE公式アカウントには表示されません</Text>
            </VStack>
          </AlertDialogFooter>
        </form>
      </FormProvider>
    </AlertDialogContent>
  );
};
